import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as jstz from 'jstz';

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const timezone = jstz.determine();

    request = request.clone({ headers: request.headers.append('TimeZoneOffset', `${new Date().getTimezoneOffset()}`) })
    request = request.clone({ headers: request.headers.append('TimeZoneName', timezone.name()) })
    
    return next.handle(request);
  }
}
