import { Injectable } from '@angular/core';
import { GroupTabModel } from 'apps/vida/src/modules/shared/models/no-cogs-material.model';

import { ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class EventHubService {
  onGroupUpdated$ = new Subject<any>();
  onGroupPlantUpdated$ = new Subject<any>();
  onReloadTabHistory$ = new Subject<any>();
  openIfactSearch$ = new Subject<any>();
  refreshFluids$ = new Subject<any>();
  updateWell$ = new Subject<any>();
  openFluidSetting$ = new Subject<any>();
  updateUserSetting$ = new Subject<any>();
  openContractSetting$ = new Subject<any>();
  openViewComments$ = new Subject<any>();
  useGroupFromJob$ = new ReplaySubject<GroupTabModel>();
  onSoInvalidInCp4$ = new Subject<any>();
  onCp3SpredSheetDownload$ = new Subject<any>();
  onPumpSheduleUpdateUpdateCasingEquipmentPump$ = new Subject<any>();
  onCallSheetGenerated$ = new Subject<any>();
  onSAPUpdates$ = new Subject<any>();
  onChangedSAP$ = new Subject<any>();
  onMixChanged$ = new Subject<any>();
  onMixUpdate$ = new Subject<any>();
  onRemoveSupplemental$ = new Subject<any>();
  onRemoveSupplementalModalSuccess$ = new Subject<any>();
  onCanceledSameLiquids$ = new Subject<any>();
  onFoundChanges$ = new Subject<any>();
  onClosedConfirmationDialog$ = new Subject<any>();
  onLSCalculatorDownload$ = new Subject<any>();
  onHandoverReady$ = new Subject<any>();
  onRigPanelShow$ = new Subject<any>();
  onWellPanelShow$ = new Subject<any>();
  constructor() { }
}
