export class TestInfo {

    public constructor (
        public requestTestId: number,
        public slurryId: number,
        public slurryNumber: number,
        public testName: string,
        public testStatusDescription: string,
        public testTempF: number,
        public testResult: TestFieldInfo[],
    ) {
    }
}

export class TestFieldInfo {

    public constructor (
        public testFieldId: number,
        public testFieldDesc: string,
        public result: string,
    ) {
    }
}

export class PumpScheduleStageTestTable {

    public constructor (
        public id: string,
        public pumpScheduleStageId: string,
        public name: string,
        public selectedSlurryNo: number,
        public pumpScheduleStageTests: PumpScheduleStageTest[],
    ) {
    }
}

export class PumpScheduleStageTest {

    public constructor (
        public id: string,
        public pumpScheduleStageTestTableId: string,
        public requestTestId: number,
        public testName: string,
        public testStatusDescription: string,
        public testTempF: number,
        public isDeleted: boolean,
        public requestId: number,
        public slurryNo: number,
        public slurryId: number,
        public pumpScheduleStageTestFields: PumpScheduleStageTestField[],
    ) {
    }
}

export class PumpScheduleStageTestField {

    public constructor (
        public id: string,
        public pumpScheduleStageTestId: string,
        public testFieldId: number,
        public testFieldDesc: string,
        public result: string,
    ) {
    }
}
