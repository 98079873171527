<div class="flx-ctn-row">
  <div *ngIf="(!controlPointNumber || isCP1) && (isJobReviewer$ | async) && (showCogs$ | async)"
    class="flx-itm-12-12 flx-itm-xs-6-12 p-t-20 p-r-20" [formGroup]="pumpScheduleForm">
    <div class="panel panel-custom no-padding">
      <div class="panel-body">
        <div class="ui-table">
          <table>
            <thead>
              <tr>
                <th>Job COGS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="vertical-align-middle">
                  <div
                    *ngIf="pumpScheduleForm.controls.totalCOGS.value === null && pumpScheduleForm.controls.pumpScheduleId.value">
                    <span class="warning pr-1">Not all COGS available</span>
                    <span class="fa fa-question-circle icon-help pl-1" (click)="openJobCogsHelp.emit()"></span>
                  </div>
                  <span
                    *ngIf="!pumpScheduleForm.controls.pumpScheduleId.value || pumpScheduleForm.controls.totalCOGS.value !== null"
                    class="unit-value">
                    ${{getJobCOGS()}}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>


  <div class="flx-itm-12-12 flx-itm-xs-6-12 p-t-20 p-r-20" [formGroup]="pumpScheduleForm">
    <div class="panel panel-custom no-padding">
      <div class="panel-heading">Spacer Mix Method</div>
      <div class="panel-body">
        <div class="ui-table">
          <table>
            <thead>
              <tr>
                <th>Method</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="vertical-align-middle">
                  <div>
                    <p-dropdown [options]="spacerMixMethods" formControlName="spacerMixMethod"
                      [lockableElement]="'pumpSchedule_spacerMixMethod'" (onChange)="onSpacerMixMethodChanged()">
                    </p-dropdown>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="flx-itm-12-12 flx-itm-xs-6-12 p-t-20 p-r-20" [formGroup]="pumpScheduleForm">
    <div class="panel panel-custom no-padding">
      <div class="panel-heading">Shoe Track</div>
      <div class="panel-body">
        <div class="ui-table">
          <table>
            <thead>
              <tr>
                <th>Length</th>
                <th *ngIf="controlPointNumber === 0">Actual Length</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="vertical-align-middle">
                  <div class="input-unit" [lockableElement]="'ShoeTrack_shoeTrackLength'">
                    <input-unit formControlName="shoeTrackLength" unit={{UnitType.Distance}}
                      class="exception-disable-item"></input-unit>
                  </div>
                  <div class="custom-unit" dUnitName={{UnitType.Distance}}></div>
                </td>
                <td class="vertical-align-middle" *ngIf="controlPointNumber === 0">
                  <div class="custom-unit display-center" dUnitName={{UnitType.Distance}}></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="ui-table">
          <table>
            <thead>
              <tr>
                <th>Volume</th>
                <th *ngIf="controlPointNumber === 0">Actual Volume</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="vertical-align-middle" [lockableElement]="'ShoeTrack_shoeTrackVolume'">
                  <div class="input-unit">
                    <input-unit formControlName="shoeTrackVolume" unit={{UnitType.LargeVolume}}
                      class="exception-disable-item"></input-unit>
                  </div>
                  <div class="custom-unit" dUnitName={{UnitType.LargeVolume}}></div>
                </td>
                <td class="vertical-align-middle" *ngIf="controlPointNumber === 0">
                  <div class="custom-unit display-center" dUnitName={{UnitType.LargeVolume}}></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="flx-itm-12-12 flx-itm-xs-6-12 p-t-20 p-r-20" [formGroup]="pumpScheduleForm">
    <div class="panel panel-custom no-padding custom-panel">
      <div class="panel-body">
        <div class="ui-table">
          <table>
            <thead>
              <tr>
                <th>Scheduled Shutdown (hh:mm) {{job.isLiner ? '*' : ''}}</th>
                <th>Target Safety Factor (hh:mm)</th>
                <th class="p-b-0">
                  <p class="info-batch-mixing-time">Batch Mixing Time (hh:mm)</p>
                  <div class="more-info-icon tip-batch-mixing-time">
                    <div class="more-info-icon-target"></div>
                    <i class="fa fa-info-circle icon-info" (click)="infoBatchMixingTime.toggle($event)"></i>
                    <p-overlayPanel #infoBatchMixingTime [dismissable]="true" [showCloseIcon]="true"
                      styleClass="cpoint-overlay-panel" [showTransitionOptions]="'10ms'">
                      Batch Mixing Time is for Display and Reporting purposes only. Not used in calculations.
                    </p-overlayPanel>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="input-with-100" [ngClass]="checkDisableItem()">
                    <p-inputMask [lockableElement]="'pumpSchedule_shutdown'" type="text" styleClass="form-control"
                      mask="99:99" formControlName="scheduledShutdown" placeholder="hh:mm"
                      (onBlur)="updateMinTTAndActualSafety()"></p-inputMask>
                  </div>
                </td>
                <td>
                  <div class="input-with-100" [ngClass]="checkDisableItem()">
                    <p-inputMask [lockableElement]="'pumpSchedule_targetSafetyFactor'" type="text"
                      styleClass="form-control" mask="99:99" formControlName="targetSafetyFactor" placeholder="hh:mm"
                      (onBlur)="updateMinTTAndActualSafety()"></p-inputMask>
                  </div>
                </td>
                <td>
                  <div class="input-with-100" [ngClass]="checkDisableItem()">
                    <p-inputMask [lockableElement]="'pumpSchedule_batchMixingTime'" type="text"
                      styleClass="form-control" mask="99:99" formControlName="batchMixingTime" placeholder="hh:mm">
                    </p-inputMask>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="isJobTypeLiner && (controlPointNumber === 1 || (isJobEdit | async))"
    class="flx-itm-12-12 flx-itm-xs-6-12 p-t-20 p-r-20">
    <pump-schedule-liner-job [pumpScheduleForm]="pumpScheduleForm" [cpState]="cpState" [job]="job">
    </pump-schedule-liner-job>
  </div>

  <div class="flx-itm-12-12 flx-itm-xs-6-12 p-t-20 p-r-20" [formGroup]="jptFormGroup">
    <div class="panel panel-custom no-padding">
      <div class="panel-heading">Job Placement Time</div>
      <div class="panel-body">
        <p-table [value]="jptFormGroup.controls.formArr['controls']">
          <ng-template pTemplate="header">
            <tr>
              <th class="width-column-num">Stage</th>
              <th class="width-column-17">Slurry Type</th>
              <th>Tested TT (hh:mm)*</th>
              <th class="width-column-17">JPT (hh:mm)</th>
              <th class="width-column-17">
                <span class="info-min-tt">Min TT (hh:mm)</span>
                <div class="more-info-icon tip-min-tt">
                  <div class="more-info-icon-target"></div>
                  <i class="fa fa-info-circle icon-info"
                    (click)="onSelectedOverlayPanel($event, infoMinTT, infoActualSafety)"></i>
                  <p-overlayPanel #infoMinTT [dismissable]="true" [showCloseIcon]="true"
                    styleClass="cpoint-overlay-panel">
                    Min TT = JPT + Scheduled Shutdown + Target Safety Factor.
                  </p-overlayPanel>
                </div>
              </th>
              <th class="width-column-17">
                <p class="info-actual-safety">Actual Safety (hh:mm)</p>
                <div class="more-info-icon tip-actual-safety">
                  <div class="more-info-icon-target"></div>
                  <i class="fa fa-info-circle icon-info"
                    (click)="onSelectedOverlayPanel($event, infoActualSafety, infoMinTT)"></i>
                  <p-overlayPanel #infoActualSafety [dismissable]="true" [showCloseIcon]="true"
                    styleClass="cpoint-overlay-panel">
                    Actual Safety = Tested TT - JPT.
                  </p-overlayPanel>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-itemArrJpt>
            <tr [formGroup]="itemArrJpt">
              <td class="width-column-num vertical-align-middle bold" style="color: white"
                [style.backgroundColor]="pumpScheduleAdapter.hashColors[itemArrJpt.controls.stageNumber.value - 1]">
                {{itemArrJpt.controls.stageNumber.value}}</td>
              <td class="width-column-17 vertical-align-middle">{{itemArrJpt.controls.slurryType.value}}</td>
              <td>
                {{itemArrJpt.controls.thickeningTime.errors !== null ? itemArrJpt.get('thickeningTime').errors['messageTime'] : '' }}
                <div class="input-with-tooltips"
                  [class.exception-disable-item]="cp2State !== 'Completed' && cp4State !== 'Completed' && (cpState === ControlPointState.Approved || cpState === ControlPointState.Submitted)">
                  <p-inputMask [lockableElement]="'pumpSchedule_thickeningTime'" type="text" styleClass="form-control"
                    [class.warning]="shouldDisplayTTWarning(itemArrJpt)" (onBlur)="changeThickeningTime(itemArrJpt)"
                    (onComplete)="validateThickeningTime(itemArrJpt.value)" mask="99:99"
                    formControlName="thickeningTime" placeholder="hh:mm"></p-inputMask>

                  <div *ngIf="shouldDisplayTTError(itemArrJpt)" class="more-info-icon error">
                    <div class="more-info-icon-target"></div>
                    <i class="fa fa-info-circle" (click)="errorThickening.toggle($event)"></i>
                    <p-overlayPanel #errorThickening [dismissable]="true" [showCloseIcon]="true"
                      styleClass="cpoint-overlay-panel">
                      WARNING: Tested Thickening Time should not be equal to or less than Job Placement Time.
                    </p-overlayPanel>
                  </div>

                  <div *ngIf="shouldDisplayTTWarning(itemArrJpt)" class="more-info-icon warning">
                    <div class="more-info-icon-target"></div>
                    <i class="fa fa-info-circle" (click)="warningThickening.toggle($event)"></i>
                    <p-overlayPanel #warningThickening [dismissable]="true" [showCloseIcon]="true"
                      styleClass="cpoint-overlay-panel">
                      WARNING: Tested Thickening Time should not be less than Minimum Thickening Time.
                    </p-overlayPanel>
                  </div>
                </div>

                <error-display formControlName="thickeningTime"></error-display>
              </td>
              <td class="width-column-17 vertical-align-middle">{{itemArrJpt.controls.jpt.value}}</td>
              <td class="width-column-17 vertical-align-middle">{{itemArrJpt.controls.minThickeningTime.value}}</td>
              <td class="width-column-17 vertical-align-middle">{{itemArrJpt.controls.actualSafety.value}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

</div>
