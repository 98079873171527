import { Directive, AfterViewInit, ElementRef, ContentChild, OnDestroy } from '@angular/core';
import { fromEvent, Subject, merge } from 'rxjs';
import { filter, single, first, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { OverlayPanel } from 'primeng/overlaypanel';

@Directive({
  selector: '[pAccordionTabEx]'
})
export class PAccordionTabExDirective implements OnDestroy, AfterViewInit {
  @ContentChild(OverlayPanel) overlayPanel: OverlayPanel;

  private destroy$ = new Subject();

  constructor(
    private el: ElementRef,
  ) {
  }

  ngAfterViewInit(): void {
    const btnLink = this.el.nativeElement.querySelector('.btn-right-underline');
    const btnInfo = this.el.nativeElement.querySelector('.fa');

    if (this.overlayPanel) {
      this.overlayPanel.onShow.subscribe(_ => {
        const btnClose = this.el.nativeElement.querySelector('.p-overlaypanel-close-icon');
        const documentSubscription = fromEvent(document, 'click').pipe(
          takeUntil(this.destroy$),
          first(e => e.target !== btnInfo)
        ).subscribe((e) => {
          this.stopEvent(e);
          this.overlayPanel.hide();
        });

        fromEvent(btnClose, 'click').pipe(
          takeUntil(this.destroy$)
        ).subscribe((e: any) => {
          this.stopEvent(e);
          this.overlayPanel.hide();
          documentSubscription.unsubscribe();
        });
      });
    }

    if (btnLink) {
      fromEvent(btnLink, 'click').pipe(
        takeUntil(this.destroy$)
      ).subscribe((e: any) => {
        this.stopEvent(e);
        this.overlayPanel && this.overlayPanel.hide();
      });
    }

    if (btnInfo) {
      fromEvent(btnInfo, 'click').pipe(
        takeUntil(this.destroy$)
      ).subscribe((e: any) => {
        this.stopEvent(e);
        this.overlayPanel && this.overlayPanel.toggle(e);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private stopEvent(event: { preventDefault: () => void; stopPropagation: () => void; }) {
    event.preventDefault();
    event.stopPropagation();
  }
}
