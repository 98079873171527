import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ErrorMessageModel, ConfirmDialogService, PDropDownModel } from 'libs/ui';
import { ERROR_TYPE, ERROR_MESSAGE, SlurrySource } from 'libs/constants';
import { IFactsRequestInfo, Job } from 'libs/models';
import { ApplicationStateService } from 'libs/shared/services';
import { EditJobAdapter } from '../../../edit-job/adapters';
import { combineLatest, Subscription, Observable, concat, of } from 'rxjs';
import { AutoUnsubscribe } from 'libs/helpers/subscription-helper';
import { finalize, map, shareReplay } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'fluid-request-info',
  templateUrl: './fluid-request-info.component.html',
  styleUrls: ['./fluid-request-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FluidRequestInfoComponent implements OnChanges, OnInit, OnDestroy {
  @Input() fluidFormGroup: UntypedFormGroup;
  @Input() canEdit: boolean;
  @Input() job: Job;
  @Output() copyRequest = new EventEmitter();
  @Output() createRequest = new EventEmitter();
  @Output() deleteFluidDetail = new EventEmitter();
  @Output() onFluidChange = new EventEmitter();
  @Output() onActualVolumeChange = new EventEmitter();
  public isAddPumpScheduleVisible$ = new Observable<boolean>();
  slurryOptions: PDropDownModel[] = [];
  slurrySource = SlurrySource;
  isMudSlurryType = false;
  subscription = new Subscription();
  internalSubscription = new Subscription();

  get testTypeData$(): Observable<PDropDownModel[]> {
    return this.editJobAdapter.testTypeData$.pipe(finalize(() => this.cd.markForCheck()));
  }

  get slurryTypeData$(): Observable<PDropDownModel[]> {
    return this.editJobAdapter.slurryTypeData$.pipe(finalize(() => this.cd.markForCheck()));
  }

  get Name() {
    return this.fluidFormGroup.get('name').value;
  }

  get createPresent() {
    return (this.fluidFormGroup?.controls?.requestId.value == null || this.fluidFormGroup?.controls?.slurrySource.value === this.slurrySource.HDFFluid) && !this.isMudSlurryType;
  }

  get copyPresent() {
    return this.fluidFormGroup?.controls?.requestId.value != null && !this.isMudSlurryType;
  }

  get removePresent() {
    return this.fluidFormGroup?.controls?.primaryStatus.value == null || this.fluidFormGroup?.controls?.primaryStatus.value === 'None';
  }

  errorMessages = {
    testTypeId: [
      new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Test Type'))
    ],
    slurryTypeId: [
      new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Slurry Type'))
    ],
    density: [
      new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Slurry Density'))
    ],
    sapMaterialName: [
      new ErrorMessageModel('notExisted', 'SAP Material does not exist. Please input another value.')
    ]
  };

  constructor(
    protected editJobAdapter: EditJobAdapter,
    private confirmDialogService: ConfirmDialogService,
    private cd: ChangeDetectorRef,
    public appStateService: ApplicationStateService,
  ) { }

  ngOnInit() {
    this.isAddPumpScheduleVisible$ = concat(
      of(this.job.isStageJob),
      this.appStateService.jobParams$
          .pipe(
              map(c => {
                  const visible = c.isStageJob;
                  return visible;
              }),
              shareReplay()
          )
    );
    this.subscription.add(this.fluidFormGroup.controls.slurryTypeId.valueChanges.subscribe(value => {
      const slurryType = this.editJobAdapter.slurryTypeData$.value.find(x => x.value === value);
      this.isMudSlurryType = slurryType && slurryType.label === 'Mud';
      this.cd.markForCheck();
    }));

    this.subscription.add(
      combineLatest([
        this.fluidFormGroup.controls.primaryStatus.valueChanges,
        this.fluidFormGroup.controls.primaryStatusId.valueChanges,
      ])
        .subscribe(_ => {
          this.cd.markForCheck();
          this.cd.detectChanges();
        })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const { fluidFormGroup } = changes;

    if (this.internalSubscription && !this.internalSubscription.closed) {
      this.internalSubscription.unsubscribe();
    }

    this.internalSubscription = new Subscription();

    if (fluidFormGroup) {
      this._updateSlurryOptions(this.fluidFormGroup.controls.requestInfo.value);
      this.internalSubscription.add(this.fluidFormGroup.controls.requestInfo.valueChanges.subscribe(requestInfo => {
        this._updateSlurryOptions(requestInfo);
      }));

      const slurryType = this.editJobAdapter.slurryTypeData$.value.find(x => x.value === this.fluidFormGroup.controls.slurryTypeId.value);
      this.isMudSlurryType = slurryType && slurryType.label === 'Mud';
    }
  }

  changeFluidName(event) {
    this.editJobAdapter.updateFluidsFromFluidTab$.next(
      {
        id: this.fluidFormGroup.get('id').value === null ? this.fluidFormGroup.get('tempId').value
          : this.fluidFormGroup.get('id').value,
        name: event.target.value
      }
    )
  }

  _updateSlurryOptions(requestInfo: IFactsRequestInfo) {
    if (requestInfo && requestInfo.availableSlurries && requestInfo.availableSlurries.length) {
      this.slurryOptions = requestInfo.availableSlurries.map(option => new PDropDownModel(option.number, option.id));
    }

    this.cd.markForCheck();
  }

  _onFluidChange(option: PDropDownModel) {
    this.fluidFormGroup.controls.slurryId.setValue(+option.value);
    this.fluidFormGroup.controls.slurryNo.setValue(+option.label);
    this.onFluidChange.emit();
  }



  _deleteFluid() {
    this._showConfirmDeleteFluid();
  }

  _showConfirmDeleteFluid() {
    this.confirmDialogService.show({
      message: `Are you sure to remove this Fluid?`,
      header: 'Confirmation',
      accept: () => {
        this.deleteFluidDetail.next(null);
      },
      reject: () => {

      },
      acceptLabel: 'Yes',
      rejectLabel: 'No'
    });
  }

  ngOnDestroy(): void {
    this.internalSubscription.unsubscribe();
  }
}
