<p-sidebar [(visible)]="isShowUserSettingSidebar" (onHide)="onHideSidebar()" position="right" blockScroll="true" appendTo="body"
           styleClass="vida-sidebar">
    <div class="sidebar-header">
        <h1>User Settings
            <div class="fa fa-question-circle icon-help" (click)="openHelp()"></div>
        </h1>
    </div>

    <div class="sidebar-content" *ngIf="currentUserUnits && userSettingService.userSettings">
        <div class="form-group row">
            <div class="col-sm-12 lb-text">
                <label>Reports Language</label>
            </div>

            <div class="col-sm-9 language-control">
                <p-dropdown [options]="getSupportedReportLanguages()" optionLabel="reportsLanguage"
                            (onChange)="updateReportLanguage($event)"
                            [(ngModel)]="selectedReportLanguage">
                </p-dropdown>
            </div>

            <div class="col-sm-12 lb-text">
                <label>Units of Measure</label>
            </div>
            <div class="col-sm-12 content-left">
                <div class="member-details col-sm-2" *ngFor="let type of unitSystemTypes;let i = index">
                    <div class="col-sm-3 lft-padding">
                        <div>
                            <p-checkbox class="lft-padding pi"
                                        (click)="chooseUnits(type.name, type.id)"
                                        id="{{type.name}}"
                                        name="units"
                                        [value]="type"
                                        [inputId]="type.id"
                                        [(ngModel)]="userUnitSystem">
                            </p-checkbox>
                            <label class="lft-padding" for="{{type.name}}"></label>
                        </div>
                    </div>
                    <div class="col-sm-1 pad-left">{{type.name}}</div>
                    <div class="col-sm-1"></div>
                </div>
            </div>
        </div>

        <ng-template [ngIf]="unitSystemSetting">
            <ng-template [ngIf]="unitOfMeasureSelections.API">
                <div class="row" *ngFor="let unit of unitSystemSetting; let i = index">
                    <div class="col-12 col-md-8">
                        <div class="form-group">
                            <span style="font-weight: bold;">{{unit.unitClass.name}}</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 left-input">
                        <div class="form-group"> {{unit.unitMeasure.name}} </div>
                    </div>
                </div>
            </ng-template>

            <ng-template [ngIf]="unitOfMeasureSelections.SI">
                <div class="row" *ngFor="let unit of unitSystemSetting; let i = index">
                    <div class="col-12 col-md-8">
                        <div class="form-group">
                            <span style="font-weight: bold;">{{unit.unitClass.name}}</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 left-input">
                        <div class="form-group">
                            {{unit.unitMeasure.name}}
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template [ngIf]="unitOfMeasureSelections.Custom">
                <div class="row custom-unit-measurement" *ngFor="let unitsClass of unitClasses; let i = index">
                    <div class="col-12 col-md-8 right-text">
                        <div class="form-group custom-text">
                            <span style="font-weight: bold;">{{unitsClass.name}}</span>
                        </div>
                    </div>

                    <div class="col-12 col-md-4 left-input">
                        <div class="input-group">
                            <p-dropdown appendTo="body" [options]="getUnitsMeasureByUnitClassId(unitsClass.id)" (onChange)="updateSelectedUnit($event, i)" optionLabel="name"
                                        [(ngModel)]="currentUserUnits.unitSystemSettings[i].unitMeasure">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>

    <div class="sidebar-footer">
        <a class="btn lnk-cancel" (click)="hideUserSetting()">Cancel</a>
        <button type="button" class="btn primary-btn" (click)="flag=true; updateAllUserSetting()">SAVE</button>
    </div>
</p-sidebar>
