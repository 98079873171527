import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { DynamicSidebarContent } from 'libs/ui';
import { Subscription, forkJoin, Observable } from 'rxjs';
import { map, finalize, tap, switchMap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { HttpCommanderService, ApplicationStateService, HdfService, FileService, TaskDownloadStatus, DownloadStatusEnum } from 'libs/shared/services';
import { SeparateWordsBySpaces } from '../../../shared/helpers/string-utils.helper';
import { VidaFileType } from '../../../shared/constant';
import { environment } from 'libs/environment';
import { ExternalDocumentsService } from '../../services/external-documents.service';
import { IIcemFileImportModel } from '../../../shared/models';
import { Job, PumpSchedule } from 'libs/models';
import { PumpScheduleStateFactory } from '../../../pump/state/state-factory';
import { PumpScheduleFormManager } from '../../../pump/form-manager';

@Component({
  selector: 'hdf-document-sidebar',
  templateUrl: './hdf-document-sidebar.component.html',
  styleUrls: ['./hdf-document-sidebar.component.scss'],
  providers: [ PumpScheduleStateFactory, PumpScheduleFormManager ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HdfDocumentSidebarComponent implements DynamicSidebarContent, OnInit, OnDestroy {
  @Input() job: Job;
  @Output() selectionChanged: EventEmitter<boolean> = new EventEmitter();

  title: string = 'Download HDF Documents';
  sidebarDesc: string;
  loadingSubscription: Subscription;
  loadingDonwloadSubscription: Subscription;
  objectKeys = Object.keys;
  data = { jobId: null, wellId: null };

  jobId: string;
  wellName: string;
  wellId: string;
  destinationFolderPath: string;

  filesSelected: boolean;
  selectAllChecked: boolean;
  selectAllDisabled: boolean;
  hdfFileTypes: any[] = [];
  hdfDocGroupName: any = {
    CallSheet: 'Call Sheet',
    ControlPoint3: 'Control Point 3',
    JobProgramCustomerApproval: 'Job Program Customer Approval',
    iCemDesignRealtime: 'iCem Design/iCem Realtime',
    PostJobReport: 'Post Job Report',
    JobLog: 'Job Log',
    JobProgram: 'Job Program'
  };
  jsqaExistedMessage = '* A JSQA/ CP3 Document already exists in VIDA. Downloading another one will not overwrite the existing file.';
  private typeAndGroupMapping = {
    'CallSheet': this.hdfDocGroupName.CallSheet,
    'ControlPoint3': this.hdfDocGroupName.ControlPoint3,
    'CustomerApproval': this.hdfDocGroupName.JobProgramCustomerApproval,
    'iCem': this.hdfDocGroupName.iCemDesignRealtime,
    'iCem.Design': this.hdfDocGroupName.iCemDesignRealtime,
    'iCem.Realtime': this.hdfDocGroupName.iCemDesignRealtime,
    'JobChart': this.hdfDocGroupName.PostJobReport,
    'JobSummaryReport': this.hdfDocGroupName.PostJobReport,
    'JobLog': this.hdfDocGroupName.JobLog,
    'Proposal': this.hdfDocGroupName.JobProgram,
  };
  private subscription = new Subscription();

  objGroupedFileList: any;
  orderedGroupedFileList: any[] = [];
  message: string;

  downloadHdfAttachmentsSubscription: Subscription;
  private _downloadToken: string = null;

  constructor(
    private hdfService: HdfService,
    private hcService: HttpCommanderService,
    private messageService: MessageService,
    private applicationStateService: ApplicationStateService,
    private externalDocumentsService: ExternalDocumentsService,
    private cd: ChangeDetectorRef,
    private fileService: FileService
  ) { }

  ngOnInit() {
    this.jobId = this.job?.id;
    this.wellName = this.job?.wellName;
    this.wellId = this.job?.wellId;
    this.destinationFolderPath = `VIDA/${this.wellId}/${this.jobId}/iCem/Realtime`;
    this.loadFileTypes();

    this.subscription.add(
      this.externalDocumentsService.jobDataChanged$.subscribe(data => {
        this.data = data;
        this.loadFileList();
      })
    );

    this.subscription.add(
      this.externalDocumentsService.documentsDownload$.subscribe(x => {
        this.downloadAttachmentsWithHcLinks();//bug 403009
      })
    );

    this.subscription.add(
      this.externalDocumentsService.cancelationDownload$.subscribe(_ => {
        this.hcService.abortJobAttachmentsDownload(this._downloadToken);
      })
    );
  }

  ngOnDestroy() {
    if (this.loadingSubscription)
      this.loadingSubscription.unsubscribe();
    if (this.loadingDonwloadSubscription)
      this.loadingDonwloadSubscription.unsubscribe();

    this.subscription.unsubscribe();
  }

  loadFileList(onCompleteCb = null) {
    this.externalDocumentsService.showLoadingIndicator();
    forkJoin(
      this.hdfService.getAttachments(this.data.jobId),
      this.checkExistJSQAFile()
    )
      .pipe(
        map((data) => {
          const [hdfDocList, jsqaStatus] = data;
          this.jsqaExistedMessage = jsqaStatus ? this.jsqaExistedMessage : '';
          return this.groupingFilesToList(hdfDocList, jsqaStatus);
        }),
        finalize(onCompleteCb)
      )
      .subscribe((data) => {
        this.orderedGroupedFileList = data;
        this.sidebarDesc = data && data.length
          ? 'Choose the HDF files to download. *If already checked, the file has been downloaded.'
          : 'No documents available.';
        this.updateSelectAllChkbxStatus();

        this.externalDocumentsService.hideLoadingIndicator();

        this.cd.markForCheck();
      });
  }

  loadFileTypes() {
    this.hdfService.getAttachmentTypes().subscribe(_ => _.map(attachmentType => {
      this.hdfFileTypes.push({ value: attachmentType.value, label: attachmentType.name });
    }));
  }

  groupingFilesToList(fileList: any[], jsqaCheck = false) {
    const allOthersGroupName = 'All Others';
    const result = fileList.reduce((obj, item) => {
      const groupNameByType = this.typeAndGroupMapping[item.attachmentType] || allOthersGroupName;
      obj[groupNameByType] = obj[groupNameByType] || [];

      item.attachmentTypeName = SeparateWordsBySpaces(item.attachmentType);
      item.isSelected = item.isDownloaded;
      obj[groupNameByType].push(item);
      return obj;
    }, {});

    // reordering object properties by group
    const orderedListResult = [];
    Object.keys(this.hdfDocGroupName).forEach((key) => {
      const groupName = this.hdfDocGroupName[key];
      if (result[groupName] && result[groupName].length > 0) {
        // set default value for iCem file type
        if (groupName === this.hdfDocGroupName.iCemDesignRealtime) {
          result[groupName].forEach((item) => {
            if (item.attachmentType.split('.').length !== 2) {
              item.attachmentType = 'iCem.Realtime';
            }
          });
        }
        if (groupName === this.hdfDocGroupName.ControlPoint3 && jsqaCheck) {
          // treat it downloaded to mark disable
          result[groupName].forEach((item) => {
            item.isDownloaded = true;
          });
        }

        orderedListResult.push({
          groupKey: key,
          groupName: this.hdfDocGroupName[key],
          items: this.sortFileList(result[groupName])
        });
      }
    });

    if (result[allOthersGroupName] && result[allOthersGroupName].length > 0) {
      const items = this.sortFileList(result[allOthersGroupName])
        .filter(x => x && x.attachmentUrl);
      if (items.length) {
        orderedListResult.push({
          groupKey: 'AllOthers',
          groupName: allOthersGroupName,
          items
        });
      }

    }

    return orderedListResult;
  }

  sortFileList(arr: any[]) {
    return (arr || [])
      .sort((a, b) => a.attachmentType.localeCompare(b.attachmentType) || a.attachmentName.localeCompare(b.attachmentName));
  }

  preparePostDataFromFileList(groupedFileList): any[] {
    let selectedFiles = [];
    groupedFileList.forEach((group) => {
      const files = group.items.filter(x => !x.isDownloaded && x.isSelected)
        .map(x => {
          return {
            attachmentName: x.attachmentName,
            attachmentType: x.attachmentType,
            attachmentUrl: x.attachmentUrl,
            checkSum: x.checkSum,
          };
        });

      if (files.length > 0) {
        selectedFiles = selectedFiles.concat(files);
      }
    });

    return selectedFiles;
  }

  showDownloadSuccess(status: TaskDownloadStatus) {
    this.loadFileList(() => {
      this.applicationStateService.$checkCompleteCP3.next(null);
      this.applicationStateService.$checkJobLogFileDownloaded.next(null);
      this.externalDocumentsService.finishDownload();
      if (status.isCancelled){
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: `The download progress of HDF files has been terminated: ${status.message}` });
      }
      else {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `Download successfully: ${status.message}`});
      }

    });
    this.externalDocumentsService.hideLoadingIndicator();
    this.externalDocumentsService.finishDownload();
  }

  async downloadAttachmentsWithHcLinks() {
    const postData = this.preparePostDataFromFileList(this.orderedGroupedFileList);

    let mayDownload = await this.fileService.mayDownloadFromExternalDocuments(postData.map(e => e.attachmentName));
    if (postData.length === 0 || !mayDownload) {
      return;
    }

    this.externalDocumentsService.startDownload();

    this.downloadHdfAttachmentsSubscription = this.hcService.downloadHdfReports(this.data.jobId, this.data.wellId, postData)
      .subscribe((status: TaskDownloadStatus) => {
        if (status.status === DownloadStatusEnum.STARTED){
          this._downloadToken = status.taskId;
        }
        else if (status.status === DownloadStatusEnum.PENDING){
          //wait for execution
        }
        else if (status.status === DownloadStatusEnum.INPROGRESS){
          //calculate progressbar
        }
        else if (status.status === DownloadStatusEnum.DONE) {
          this.filesSelected = false;
          this.selectAllChecked = false;
          this.selectionChanged.emit(false);

          let realtimeFiles = postData.filter(x => x.attachmentType  == "iCem.Realtime");
          if (realtimeFiles.length > 0) {
            this.parseIcemFiles(realtimeFiles).subscribe(() => {
              this.showDownloadSuccess(status);
            });
          }
          else{
            this.showDownloadSuccess(status);
          }
        }
        else { //ERROR
          this.externalDocumentsService.finishDownload();
          const msg: string = status.message == null ?
          'Connection to HDF server was lost. Please click the ‘Download’ button to resume file transfer.'
            : status.message;
          this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: msg });
        }
      });
  }

  checkHasFilesSelected() {
    let availableFilesCount = 0;
    let selectedFilesCount = 0;
    this.orderedGroupedFileList.forEach((g: { items: { isDownloaded: any; isSelected: any; }[]; }) => {
      return g.items.forEach((f: { isDownloaded: any; isSelected: any; }) => {
        if (!f.isDownloaded) {
          availableFilesCount++;
          if (f.isSelected) {
            selectedFilesCount++;
          }
        }
      });
    });
    this.selectAllChecked = availableFilesCount === selectedFilesCount;
    this.filesSelected = selectedFilesCount > 0;
    this.selectionChanged.emit(this.filesSelected);
  }

  checkAllFilesSelected() {
    this.orderedGroupedFileList.forEach(g => g.items.forEach((f: { isDownloaded: any; isSelected: boolean; }) => {
      if (!f.isDownloaded) {
        f.isSelected = this.selectAllChecked;
        this.filesSelected = this.selectAllChecked;
        this.selectionChanged.emit(this.filesSelected);
      }
    }));
  }

  updateSelectAllChkbxStatus() {
    this.selectAllDisabled = !this.orderedGroupedFileList?.some(g => g.items?.some((f: { isDownloaded: any; }) => !f.isDownloaded));
    this.selectAllChecked = this.selectAllDisabled;
  }

  checkExistJSQAFile = () => {
    const jsqaFolderPath = `VIDA/${this.data.wellId}/${this.data.jobId}/Internal/Control Points/JSQA`;
    return this.hcService.getFilesByVirtualRelativeFolderPath(jsqaFolderPath, VidaFileType.JobSiteQualityAssurance)
      .pipe(
        map(data => {
          return this.handleFileList(data);
        })
      );
  }

  handleFileList(res) {
    const { statusCode, result } = res;
    return statusCode === 200 && result && result.length && !!result[0].filePathDownload;
  }

  init_IIcemFileImportModel(): IIcemFileImportModel{
    return {
      jobId: '',
      wellName: '',
      wellId: '',
      virtualRelativeFilePath: '',
      overwriteJobLog: false,
      icemFiles: [],
      pumpScheduleModels: [],
      isJobStage: false,
      icemFileName: '',
      cementingJobs: [],
      requestDetails: []
    };
  }

  parseIcemFiles(realtimeFiles: any[]): Observable<any> {
    return this.hcService.getIcemFiles(this.jobId, this.wellId, true, true).pipe(
      switchMap((data) => {
        const files$: Observable<any>[] = [];
        const { statusCode, result } = data;
        let icemFiles = result
        for(let item of realtimeFiles) {
          const icemPayload: IIcemFileImportModel = this.init_IIcemFileImportModel();
          icemPayload.wellName = this.wellName;
          icemPayload.wellId = this.wellId;
          icemPayload.jobId = this.jobId;
          icemPayload.virtualRelativeFilePath = `${this.destinationFolderPath}`;
          icemPayload.icemFiles = icemFiles;
          icemPayload.icemFileName = item.attachmentName;
          let pumpSchedules = this.job.pumpSchedules;

          if (this.job.isStageJob) {
            icemPayload.isJobStage = true;
            pumpSchedules.forEach((pumpSchedule: PumpSchedule) => {
              const icemFile = icemFiles.find(icemFile => icemFile.pumpScheduleId == pumpSchedule.pumpScheduleId);
              if (icemFile) {
                pumpSchedule.icemFileName = icemFile.fileName;
              }
            });
          } else {
            icemPayload.isJobStage = false;
            icemPayload.virtualRelativeFilePath += `/${item.attachmentName}`;
            pumpSchedules[0].icemFileName = item.attachmentName;
          }
          icemPayload.pumpScheduleModels = pumpSchedules;
          icemPayload.overwriteJobLog = true;

          files$.push(
            this.hcService.parseIcemFile(icemPayload).pipe(
              switchMap((data) => {
                const { statusCode, result, warningMessages, anyFileDifferent } = data;

                icemPayload.cementingJobs = result;
                return this.hcService.processIcemFile(icemPayload, true);
              }))
          );
        } //for
        return forkJoin(files$);
      }));
  }
}
