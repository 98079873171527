import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';

import { concat, from, Observable, of, throwError, BehaviorSubject, Subject, iif } from 'rxjs';
import { catchError, map, mergeMap, tap, delayWhen, take } from 'rxjs/operators';

import { VidaFileType } from 'apps/vida/src/modules/shared/constant';
import { environment } from 'libs/environment';
import { FileMetadata } from '../../models/files/fileMetadata';
import { FileStatus } from '../../models/files/fileStatus';
import { UploadedFile } from '../../models/files/uploadedFile';
import { UploadProgress } from '../../models/files/uploadProgress';
import { UploadSettings } from '../../models/files/uploadSettings';
import { SessionService } from './session.service';
import { MessageService } from 'primeng/api';
import { HttpCommanderService } from './http-commander.service';
import { AdminService } from './admin.service';

type FileUploadRestrictions = { [key: string]: {[key:string]: string[] | {[key:string]: string}} };

@Injectable()
export class FileService {

    public static readonly BaseUrl = `${ environment.httpCommanderUrl }/api/file`;

    public notAllowed: string[];

    private files_upload_restrictions: FileUploadRestrictions = {
        notAllowedExtensions: {
            error: {
                summary: 'File Not Uploaded:',
                detail: '.exe, .bat, .ps1 and .svg files cannot be uploaded.'
            }
        },
        damagedFile: {
            error: {
                summary: 'Error:',
                detail: 'File does not exist.'
            }
        }
    };

    private getNotAllowedExtensions(): Observable<string[]> {
        if(this.notAllowed == undefined) {
            var subject = new Subject<string[]>();
            this.adminService.getForbidenFileExtensions().subscribe(x => {
                subject.next(x);
                this.notAllowed = x;
            });
            return subject.asObservable();
        }
        return of(this.notAllowed);
    }

    public set restrictedExtentions(extensions: string[]) {
        this.notAllowed = extensions;
    }

    // TODO: make configuration being read from the server
    private _uploadSettings: UploadSettings = new UploadSettings();

    public constructor(

        private readonly _http: HttpClient,
        private _messageService: MessageService,
        private readonly _sessionService: SessionService,
        private htpCommanderService: HttpCommanderService,
        private adminService: AdminService
    ) {
    }

    public upload(
        jobId: string,
        file: File,
        destination: string,
        description: string,
        fileType: VidaFileType,
        overwrite: boolean,
        fileId: string = null,
        chunkIds: number[] = null,
        fileName: string = file.name,
        pause$ = new BehaviorSubject<boolean>(false),
        resume$ = new Subject()): Observable<UploadProgress> {

        if (file.size === 0) {

            const progress = new UploadProgress('', 0, 0, FileStatus.Failed);
            progress.message = 'File is empty. Upload cancelled.';

            return throwError(progress);
        }

        const chunksCount = this._getTotalChunks(file);

        const path = this._getFilePath(fileType, jobId);

        const fileMeta = new FileMetadata(
            fileId,
            file,
            path,
            destination,
            description,
            overwrite,
            chunksCount,
            this._uploadSettings.chunkSize,
            chunkIds,
            fileName ?? file.name);

        if (!chunkIds && !isNaN(chunksCount)) {
            chunkIds = Array.from(Array(chunksCount).keys());
        }

        if (!chunkIds) {
            return null;
        }

        let upload$ = from(chunkIds).pipe(
            mergeMap(
                chunkId => this._uploadChunk(fileMeta, chunkId),
                this._uploadSettings.parallelism
            ),
            mergeMap(_ => iif(() => pause$.value,
                          of(_).pipe(delayWhen(_ => resume$)),
                          of(_)))
        );

        if (chunksCount > 1) {

            upload$ = concat(
                upload$,
                of(new UploadProgress(fileMeta.id, fileMeta.size, fileMeta.size, FileStatus.Finalizing)),
                this._commit(fileMeta)
            );
        }

        upload$ = upload$.pipe(
            catchError(
                (response) =>
                    this._handleUploadError(response, fileMeta)
            )
        );

        return upload$;
    }

    public deleteFile(
        fileId: string,
        fileName: string
       ): Observable<Object> {

        fileName = encodeURIComponent(fileName);

        const authToken = this._sessionService.user.hcToken;
        const params = new HttpParams();
        params.append('token', authToken);

        const url = `${ FileService.BaseUrl }/${ fileId }`;

        return this._http.delete(
            url, {
                params: params
            }
        );
    }

    public async mayDownloadFromExternalDocuments(files: string[]): Promise<boolean> {

        let filesAreAllowed = true;

        for (let i = 0; i < files.length; i++) {
            let isAllowed = await this.isFileExtensionAllowed(files[i]);
            if (!isAllowed) {
                const { notAllowedExtensions: { error } } = this.files_upload_restrictions;
                this._messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', ...error})
                filesAreAllowed = false;
                break;
            }
        }

        return filesAreAllowed;
    }

    public async canUploadFile(file: File): Promise<boolean> {

        if (file) {

            if (file.size) {
                const isAllowed = await this.isFileExtensionAllowed(file.name);

                if (!isAllowed) {
                    this._messageService.clear();
                    const { notAllowedExtensions: { error } } = this.files_upload_restrictions;
                    this._messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', ...error })
                }

                return isAllowed;
            }
            else {
                const { damagedFile: { error } } = this.files_upload_restrictions;
                this._messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', ...error })
            }
        }

        return false;
    }

    private async isFileExtensionAllowed(fileName: string): Promise<boolean> {

        const [fileExtension] = fileName.split('.').slice(-1);
        const extensions = await this.getNotAllowedExtensions().pipe(take(1)).toPromise();

        return !(extensions as string[] ?? []).includes(fileExtension.toLocaleLowerCase());
    }

    private _getFilePath(fileType: VidaFileType, jobId: string): string {

        const typeSegment = encodeURIComponent(VidaFileType[fileType]);

        if (!jobId) { // there is no job Id for report templates

            return typeSegment;
        }

        return `${ typeSegment }/${ encodeURIComponent(jobId) }`;
    }

    public createFolder(wellId: string, wellNumber: string): any {
        this.htpCommanderService.createWellInfoFolderPath(wellId, wellNumber).subscribe();
    } 

    private _uploadChunk(fileMeta: FileMetadata, chunkId): Observable<UploadProgress> {
        this._prepareChunk(fileMeta, chunkId);

        const token = encodeURIComponent(this._sessionService.user.hcToken);
        const url = `${ FileService.BaseUrl }/${ fileMeta.path }?token=${ token }`;
        const formData = this._createFormData(fileMeta, chunkId);

        const request = new HttpRequest(
            'POST',
            url,
            formData, {
                reportProgress: true
            }
        );

        return this._http.request<UploadedFile>(request).pipe(
            tap(event => this._freeInMemoryBlob(event, fileMeta, chunkId)),
            map(event => this._toUploadProgress(event, fileMeta, chunkId))
        );
    }

    private _toUploadProgress(
        event: HttpEvent<UploadedFile>,
        fileMeta: FileMetadata,
        chunkId: number): UploadProgress {

        const chunk = fileMeta.chunks[chunkId];

        let uploadedFile = null;

        switch (event.type) {

            case HttpEventType.Sent:

                chunk.status = FileStatus.Uploading;
                break;

            case HttpEventType.UploadProgress:

                chunk.bytesUploaded = Math.max(event.loaded - (event.total - chunk.size), 0);
                chunk.status = FileStatus.Uploading;
                break;

            case HttpEventType.Response:

                chunk.bytesUploaded = chunk.size;
                chunk.status = FileStatus.Success;
                uploadedFile = event.body;
                break;
        }

        const totalBytesUploaded = fileMeta.chunks
             .filter(ch => ch.bytesUploaded > 0)
             .reduce((total, ch) => total + ch.bytesUploaded, 0);

        return new UploadProgress(
            fileMeta.id,
            fileMeta.size,
            totalBytesUploaded,
            this._calcFileStatus(fileMeta),
            null,
            uploadedFile
        );
    }

    private _calcFileStatus(fileMeta: FileMetadata): FileStatus {

        if (fileMeta.chunksCount === 1
            && fileMeta.chunks[0].status === FileStatus.Success) {

            return FileStatus.Success;
        }

        if (fileMeta.chunks.some(c => c.status === FileStatus.Failed)) {

            return FileStatus.Failed;
        }

        if (fileMeta.chunks.every(c => c.status === FileStatus.Preparing)) {

            return FileStatus.Preparing;
        }

        return FileStatus.Uploading;
    }

    private _commit(fileMeta: FileMetadata): Observable<UploadProgress> {

        const token = encodeURIComponent(this._sessionService.user.hcToken);
        const url = `${ FileService.BaseUrl }/assemble/${ fileMeta.path }?token=${ token }`;

        return this._http.post<UploadedFile>(
            url,
            this._createCommitPayload(fileMeta)
        ).pipe(
            map(uploadedFile =>
                new UploadProgress(
                    fileMeta.id,
                    fileMeta.size,
                    fileMeta.size,
                    FileStatus.Success,
                    null,
                    uploadedFile
                )
            )
        );
    }

    private _freeInMemoryBlob(
        event: HttpEvent<UploadedFile>,
        fileMeta: FileMetadata,
        chunkId: number): void {

        if (event.type === HttpEventType.Sent) {

            const chunk = fileMeta.chunks[chunkId];
            if (typeof MSStream === 'function'
                && chunk.blob instanceof MSStream
                && chunk.blob.msClose) {

                chunk.blob.msClose();
            }

            chunk.blob = null;
        }
    }

    private _getTotalChunks(file: File): number {

        return Math.ceil(file.size / this._uploadSettings.chunkSize);
    }

    private _prepareChunk(fileMeta: FileMetadata, chunkId: number): void {

        const chunk = fileMeta.chunks[chunkId];
        chunk.size = fileMeta.chunkSize;

        const startByte = fileMeta.chunkSize * chunkId;
        let endByte = startByte + fileMeta.chunkSize;

        if (endByte > fileMeta.size) {

            endByte = fileMeta.size;
            chunk.size = endByte - startByte;
        }

        chunk.blob = fileMeta.file;

        if (startByte !== 0 || endByte !== fileMeta.size) {

            chunk.blob = fileMeta.file.slice(startByte, endByte);
        }
    }

    private _createFormData(fileMeta: FileMetadata, chunkId: number): FormData {

        const chunk = fileMeta.chunks[chunkId];

        const formData = new FormData();

        formData.append('id', fileMeta.id);
        formData.append('name', fileMeta.name);
        formData.append('size', fileMeta.size.toString());
        formData.append('destination', fileMeta.destination);
        formData.append('description', fileMeta.description);
        formData.append('overwrite', fileMeta.overwrite.toString());
        formData.append('chunkId', chunkId.toString());
        formData.append('chunkSize', chunk.size.toString());
        formData.append('chunksCount', fileMeta.chunksCount.toString());
        formData.append('blob', chunk.blob, fileMeta.name);

        return formData;
    }

    private _createCommitPayload(fileMeta: FileMetadata): { [key: string]: string } {

        return {
            fileId: fileMeta.id,
            fileName: fileMeta.name,
            fileSize: fileMeta.size.toString(),
            destinationFolder: fileMeta.destination,
            description: fileMeta.description,
            overwrite: fileMeta.overwrite.toString(),
            chunkId: (-1).toString(),
            chunkSize: fileMeta.chunkSize.toString(),
            chunksCount: fileMeta.chunksCount.toString(),
            data: null
        };
    }

    private _handleUploadError(
        response: HttpErrorResponse,
        fileMeta: FileMetadata): Observable<UploadProgress> {

        this.deleteFile(fileMeta.id, fileMeta.name).subscribe();

        return of(new UploadProgress(null, 0, 0, FileStatus.Failed, null, null, response.status, response.error));
    }

    public addOrigenFileExtension(fileName: string, file: File): string {
        const originFileExtension = file.name.split(".").pop()

        if (fileName.endsWith(originFileExtension)) {
          return fileName
        }

        return `${fileName}.${originFileExtension}`
    }
}


declare class MSStream {
  msClose();
}
