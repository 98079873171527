import { PublicClientApplication } from '@azure/msal-browser';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import {firstValueFrom, of} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JobStateService } from 'libs/shared';
import { RoleService } from 'libs/shared';
import { SessionService } from 'libs/shared';
import { UserSettingService } from 'libs/shared';

export function appLoadingFactory(
  sessionService: SessionService,
  userSettingsService: UserSettingService,
  unitConversionService: UnitConversionService,
  roleService: RoleService,
  jobStateService: JobStateService,
  azureAdApp: PublicClientApplication,
) {
  return async () => {
    try {
      await azureAdApp.initialize();

      const result = await azureAdApp.handleRedirectPromise();

      if (!result || !result.account) {
        return await azureAdApp.loginRedirect();
      }

      const allSettings = await firstValueFrom(
        userSettingsService.getAllSettings().pipe(catchError(() => of(false)))
      );

      if (allSettings && allSettings.networkId) {
        const { name } = result.account;
        const { networkId, hcToken, locations, settings, units, unitMeasures, roles, jobState } = allSettings;

        // Update session user information
        sessionService.user.userName = name; // name = full name
        sessionService.user.prefUserName = networkId;
        sessionService.user.hcToken = hcToken;

        // Refresh user locations
        userSettingsService.refreshLocationList(locations);

        // Update user settings and unit measurements
        userSettingsService.userSettings = { ...settings };
        unitConversionService.userUnits = { ...units };
        unitConversionService.unitMeasures$.next(unitMeasures);

        // Set user roles and job state
        roleService.setRoles(roles);
        jobStateService.jobStateData = jobState;
      } else {
        roleService.setRoles([]);
      }
      return true;
    } catch (error) {
      console.error('Error during app initialization:', error);
      return false;
    }
  };
}
