import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExternalDocumentsService {

  private documentsDownloadSource = new Subject();
  private cancelationDownloadSource = new Subject();
  private documentsDownloadedSource = new Subject();
  private jobDataSource = new BehaviorSubject<any>(null);
  private loadingIndicatorSource = new Subject();
  private loadingIndicatorCounter = 0;
  private downloadingCounter = 0;

  documentsDownload$ = this.documentsDownloadSource.asObservable();
  cancelationDownload$ = this.cancelationDownloadSource.asObservable();
  documentsDownloaded$ = this.documentsDownloadedSource.asObservable();
  jobDataChanged$ = this.jobDataSource.asObservable();
  loadingIndicatorChanged$ = this.loadingIndicatorSource.asObservable();

  constructor() { }

  get loadingIndicator(): boolean {
    return this.loadingIndicatorCounter > 0;
  }

  get filesDownloading(): boolean {
    return this.downloadingCounter > 0;
  }

  showLoadingIndicator() {
    this.loadingIndicatorCounter++;
    this.loadingIndicatorSource.next(null);
  }

  hideLoadingIndicator() {
    if (this.loadingIndicatorCounter > 0)
      this.loadingIndicatorCounter--;

    if (this.loadingIndicatorCounter === 0) {
      this.loadingIndicatorSource.next(null);
    }
  }

  downloadDocuments() {
    this.documentsDownloadSource.next(null);
  }

  cancelDownload() {
    this.cancelationDownloadSource.next(null);
  }

  startDownload() {
    this.downloadingCounter++;
    this.showLoadingIndicator();
  }

  finishDownload() {
    this.hideLoadingIndicator();
    if (this.downloadingCounter > 0)
      this.downloadingCounter--;

    if (this.downloadingCounter === 0)
      this.documentsDownloadedSource.next(null);
  }

  setJobData(data: any) {
    this.jobDataSource.next(data);
  }

  getJobData() {
    return this.jobDataSource.getValue();
  }
}
