<div [formGroup]="pumpScheduleForm">
  <div class="panel panel-custom no-padding">
    <div class="panel-heading">Total Job</div>
    <div class="panel-body">
      <div class="ui-table">
        <table>
          <thead>
            <tr>
              <th *ngIf="dryWeightVisible">Dry Weight</th>
              <th *ngIf="dryVolumeVisible">Dry Volume</th>
              <th *ngIf="isPlannedScope3Co2eVisible">
                Product Carbon Footprint
                <div class="more-info-icon">
                  <div class="more-info-icon-target"></div>
                  <i class="fa fa-info-circle" (click)="disclaimer.toggle($event)"></i>
                  <p-overlayPanel #disclaimer styleClass="cpoint-overlay-panel" [dismissable]="true" [showCloseIcon]="true">
                    <div style="max-width: 390px">
                      <span class="disclaimer-text">Legal Disclaimer</span>
                      <ul class="disclaimer-text-body">
                        <li>
                          Emissions estimates are intended for demonstrative purposes only. This sustainability data is not intended for and
                          should not be used by any party for any other purpose. The information provided is not meant for reporting purposes
                          or
                          intended to fulfill any reporting requirements.
                        </li>
                        <li>
                          The information contained herein is based on estimations of input data such as operating hours based on type of
                          work,
                          generally accepted emissions factors, and Halliburton's internal work methods and models. Where appropriate,
                          reasonable
                          estimate(s) and assumption(s) including where available, OEM provided data for equipment, and realistic operating
                          scenarios were used to provide estimates. It does not represent actual measured emissions.
                        </li>
                        <li>
                          Estimates are calculated using publicly available emissions, supplier provided data, and/or internal work methods
                          where available. Where available, estimates for materials are created using information supplied from Halliburton’s
                          suppliers. This data could include a partial or complete “product carbon footprint” or “embedded carbon”.
                          Halliburton
                          does not influence and cannot verify the accuracy or methodology of the emissions data provided by suppliers.
                        </li>
                        <li>
                          Underlying assumptions and information concerning the calculation method will be made available upon request
                          subject
                          to confidentiality requirements and limitations on the ability to release third-party confidential data.
                        </li>
                        <li>
                          For additional information, please contact <a href="mailto:sustainability@halliburton.com"
                                                                        class="disclaimer-email">sustainability&#64;halliburton.com.</a>
                        </li>
                      </ul>
                    </div>
                  </p-overlayPanel>
                </div>
              </th>
              <th *ngIf="isActualScope3Co2eVisible">
                Actual Product Carbon Footprint
                <div class="more-info-icon icon-visible">
                  <div class="more-info-icon-target"></div>
                  <i class="fa fa-info-circle" (click)="disclaimer.toggle($event)"></i>
                  <p-overlayPanel #disclaimer styleClass="cpoint-overlay-panel" [dismissable]="true" [showCloseIcon]="true">
                    <div style="max-width: 390px">
                      Actual CO2e emissions are calculated when the job is Completed.
                      <br>
                      <br>
                      <span class="disclaimer-text">Legal Disclaimer</span>
                      <ul class="disclaimer-text-body">
                        <li>
                          Emissions estimates are intended for demonstrative purposes only. This sustainability data is not intended for and
                          should not be used by any party for any other purpose. The information provided is not meant for reporting purposes or
                          intended to fulfill any reporting requirements.
                        </li>
                        <li>
                          The information contained herein is based on estimations of input data such as operating hours based on type of work,
                          generally accepted emissions factors, and Halliburton's internal work methods and models. Where appropriate, reasonable
                          estimate(s) and assumption(s) including where available, OEM provided data for equipment, and realistic operating
                          scenarios were used to provide estimates. It does not represent actual measured emissions.
                        </li>
                        <li>
                          Estimates are calculated using publicly available emissions, supplier provided data, and/or internal work methods
                          where available. Where available, estimates for materials are created using information supplied from Halliburton’s
                          suppliers. This data could include a partial or complete “product carbon footprint” or “embedded carbon”. Halliburton
                          does not influence and cannot verify the accuracy or methodology of the emissions data provided by suppliers.
                        </li>
                        <li>
                          Underlying assumptions and information concerning the calculation method will be made available upon request subject
                          to confidentiality requirements and limitations on the ability to release third-party confidential data.
                        </li>
                        <li>For additional information, please contact <a href="mailto:sustainability@halliburton.com" class="disclaimer-email">sustainability&#64;halliburton.com.</a></li>
                      </ul>
                    </div>
                  </p-overlayPanel>
                </div>
              </th>
              <th *ngIf="cogsVisible$ | async">COGS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td *ngIf="dryWeightVisible" class="vertical-align-middle">
                <span class="unit-value" [apiValue]="dryWeight$ | async" [unitType]="UnitType.Weight"></span>
              </td>
              <td *ngIf="dryVolumeVisible" class="vertical-align-middle">
                <div class="unit-value" [apiValue]="dryVolume$ | async" [unitType]="UnitType.DryVolume"></div>
                <div *ngIf="anyMaterialMissingBulkDensity$ | async">
                  <span class="warning pr-1">Not all Bulk Densities available</span>
                  <span class="fa fa-question-circle icon-help pl-1" (click)="showMissingBulkDensityHelp()"></span>
                </div>
              </td>
              <td *ngIf="isPlannedScope3Co2eVisible" class="vertical-align-middle">
                <div *ngIf="(totalCo2e$ | async) > 0" class="unit-value" [apiValue]="(totalCo2e$ | async)" [unitType]="UnitType.Weight"
                     [formatNum]="0" [shouldShowFormatNum]="true"></div>
              </td>
              <td *ngIf="isActualScope3Co2eVisible" class="vertical-align-middle">
                <div *ngIf="(totalBlendActualCo2e$ | async) > 0" class="unit-value" [apiValue]="(totalBlendActualCo2e$ | async)" [unitType]="UnitType.Weight"
                     [formatNum]="0" [shouldShowFormatNum]="true"></div>
              </td>
              <td *ngIf="cogsVisible$ | async" class="vertical-align-middle">
                <div *ngIf="!(cogsAvailable$ | async)">
                  <span class="warning pr-1">Not all COGS available</span>
                  <span class="fa fa-question-circle icon-help pl-1" (click)="showCogsHelp()"></span>
                </div>
                <span *ngIf="cogsAvailable$ | async" class="unit-value">${{ cogs$ | async | number: '.2-2' }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div> 
