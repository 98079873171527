import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MATERIAL_TYPE, UnitType } from 'libs/constants';
import { PumpScheduleStageMaterialModel } from 'libs/models';
import { SelectItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { FluidStateManager } from '../../state/fluid-state-manager';
import { StageStateManager } from '../../state/stage-state-manager';
import { ViewState } from '../../view-state';

@Component({
    selector: 'stage-fluid',
    templateUrl: './stage-fluid.component.html',
    styleUrls: ['./stage-fluid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class StageFluidComponent implements OnInit, AfterViewChecked {

    private static _scheduleEditFields: string[] = [
        'loadoutVolume'
    ];

    private static _cp1Fields: string[] = [
    ];

    private static _cp2Fields: string[] = [
        ...StageFluidComponent._scheduleEditFields
    ];

    private static _cp4Fields: string[] = [
        'thickeninngTime',
        'placementTime',
        ...StageFluidComponent._scheduleEditFields
    ];

    // array index corresponds to CP number,
    // 0 index for Schedule Edit view
    private static _visibleFields: string[][] = [
        StageFluidComponent._scheduleEditFields,
        StageFluidComponent._cp1Fields,
        StageFluidComponent._cp2Fields,
        [],
        StageFluidComponent._cp4Fields
    ];

    @Input()
    public fluidState: FluidStateManager;

    @Input()
    public stageState: StageStateManager;

    @Input()
    public readonly refreshForm: Observable<any>;

    @Input()
    public readonly hasScope3access: boolean;

    constructor(
        private cd: ChangeDetectorRef
    ) {
    }

    public UnitType = UnitType;

    public MATERIAL_TYPE = MATERIAL_TYPE;

    public isDeadVolumeTypeDisabled$: Observable<boolean>;
    public stageFluidLoaded$ = new Subject();

    private get _viewState(): ViewState {

        return this.fluidState.viewState;
    }

    public get form(): UntypedFormGroup {

        return this.fluidState.form;
    }

    public get isJobEditable(): boolean {

        return this._viewState.isJobEditable;
    }

    public get isOffshoreJob$(): Observable<boolean> {

        return this._viewState.isOffshoreJob$;
    }

    public get isCP4View(): boolean {

        return this._viewState.isCP4View;
    }

    public get isDeadVolumeEditable(): boolean {

        return this._viewState.isScheduleEditView || this._viewState.isCP2View;
    }

    public get isBulkCementEditable(): boolean {

        return this.isDeadVolumeEditable;
    }

    public get isFoam$(): Observable<boolean> {

        return this.fluidState.isFoam$;
    }

    public get deadVolumeDropdownItems$(): Observable<SelectItem[]> {

        return this.fluidState.dropdownDeadVolumeItems$;
    }

    public get deadVolumeFluidTypeName$(): Observable<string> {

        return this.stageState.deadVolumeFluidTypeName$;
    }

    public get labName$(): Observable<string> {

        return this.fluidState.labName$;
    }

    public get blendName$(): Observable<string> {

        return this.fluidState.blendName$;
    }

    public get cementName$(): Observable<string> {

        return this.fluidState.cementName$;
    }

    public get water$(): Observable<string> {

        return this.fluidState.water$;
    }

    public get mixWaterUnit$(): Observable<string> {

        return this.fluidState.mixWaterUnit$;
    }

    public get sackWeight$(): Observable<number> {

        return this.fluidState.sackWeight$;
    }

    public get bulkCementDisabled$(): Observable<boolean> {

        return this.fluidState.bulkCementDisabled$;
    }

    public get yield$(): Observable<number> {

        return this.fluidState.yield$;
    }

    public get foamQuality$(): Observable<number> {

        return this.fluidState.foamQuality$;
    }

    public get hasBlendMaterials$(): Observable<boolean> {

        return this.fluidState.hasBlendMaterials$;
    }

    public get blendMaterials$(): Observable<PumpScheduleStageMaterialModel[]> {

        return this.fluidState.blendMaterials$;
    }

    public get additives$(): Observable<PumpScheduleStageMaterialModel[]> {

        return this.fluidState.additives$;
    }

    public get supplementals$(): Observable<PumpScheduleStageMaterialModel[]> {

        return this.fluidState.supplementals$;
    }

    public get blendMaterialsCount$(): Observable<number> {

        return this.fluidState.blendMaterialsCount$;
    }

    public get additivesCount$(): Observable<number> {

        return this.fluidState.additivesCount$;
    }

    public get supplementalsCount$(): Observable<number> {

        return this.fluidState.supplementalsCount$;
    }

    public get isIFactsCementFluid$(): Observable<boolean> {

        return this.fluidState.isIFactsCementFluid$;
    }

    public get plannedVolume$(): Observable<number> {

        return this.stageState.plannedVolume$;
    }

    public get thickeningTime$(): Observable<string> {

        return this.stageState.thickeningTime$;
    }

    public get placementTime$(): Observable<string> {

        return this.stageState.placementTime$;
    }

    public get enablingCPStates$(): Observable<boolean> {

        return this._viewState.isCP1Submitted$
            .pipe(
                map(isCP1Submitted => {

                    return !this._viewState.isJobCompleted
                        && (isCP1Submitted || this._viewState.isCP1Approved)
                        && !this._viewState.isCP2Completed
                        && !this._viewState.isCP4Completed;
                }),
                shareReplay()
            );
    }

    public ngOnInit(): void {

        this.isDeadVolumeTypeDisabled$ = this.fluidState.isIFactsCementFluid$
            .pipe(
                map(itis => {

                    return !itis || !this.isJobEditable;
                }),
                shareReplay()
            );

        if (this.refreshForm != null) {
            this.refreshForm.subscribe(() => {
                this.cd.detectChanges();
            });
        }
    }

    ngAfterViewChecked(): void {
        this.stageFluidLoaded$.next(null);
    }

    public isVisible$(field: string): Observable<boolean> {

        return this._viewState.isFieldVisible$(
            field,
            StageFluidComponent._visibleFields
        );
    }

    public getHeaderMaterialName(type: string, count: number): string {

        const map = {
            Blend: `Blend Materials (${count})`,
            Additives: `Additives (${count}) (* = Exclude from Total Mix Fluid)`,
            Supplemental: `Supplemental Materials (${count})`
        };

        return map[type];
    }
}
