<div *ngIf="job" [formGroup]="headerDataForm" [loadingIndicator]="loadingSubscription">
  <ng-template [ngIf]="!cpOptions" [ngIfElse]="jobheader_cp_part">
    <div class="panel panel-custom">
      <div class="panel-body cust-width">
        <div class="cust-mar-bot ui-table">
          <table>
            <thead>
              <tr>
                <th style="text-align: center;">Risk Level</th>
                <th>Job Number</th>
                <th>Customer</th>
                <th>Classification</th>
                <th>Well Name</th>
                <th>Well Number</th>
                <th>{{job.actualDate !== null ? 'Actual Date' : 'Projected Date'}}
                  <span class="" *ngIf="job.actualDate !== null">
                    <i class="fa fa-info-circle" (click)="actualDateToolTip.toggle($event)"></i>
                    <p-overlayPanel #actualDateToolTip [showCloseIcon]="true"
                                    [dismissable]="true" styleClass="cpoint-overlay-panel">
                      <span>
                        <strong>Defaults to Start Job event from iCem Job Log</strong>
                      </span>
                    </p-overlayPanel>
                  </span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="cust-risk" [ngClass]="{'cust-risk-red':job.riskLevel>3}">
                  {{job.riskLevel}}
                </td>
                <td>{{job.jobCode}}</td>
                <td>{{job.customerName}}</td>
                <td>
                  <input id="typeCMT" type="checkbox" [checked]="job.cmtSvc" disabled>
                  <label for="typeCMT" class='classification-checkbox-label'>CMT Svc</label>

                  <span class="classification-checkbox">
                    <input id="typeCE" type="checkbox" [checked]="job.ceSvc" disabled>
                    <label for="typeCE" class='classification-checkbox-label'>CE Svc</label>
                  </span>
                  <span class="classification-checkbox">
                    <input id="typeCEDS" type="checkbox" [checked]="job.ceDirectSales" disabled>
                    <label for="typeCEDS" class='classification-checkbox-label'>CE Direct Sales</label>
                  </span>
                </td>
                <td>
                  <span>
                    <a class="disable-except" [routerLink]="['/wells']" [queryParams]="{id: job.wellId}">{{job.wellName}}</a>
                  </span>
                </td>
                <td [ngStyle]="{'text-overflow': 'ellipsis', 'min-width': '200px', 'overflow': 'hidden'}">{{job.wellNumber}}</td>
                <td class="should-not-padding">
                  <div class="row flx-ctn-row">
                    <div class="col-md-10">
                      <job-projected-date #jobProjectedDate
                        [canRemove]="false"
                        [actualDate]="headerDataForm?.controls?.actualDate"
                        [isManualActualDate]="jobInformation?.controls?.isManualActualDate"
                        [projectedDate]="headerDataForm?.controls?.projectedDate"
                        (onDeleteProjectedDate)="onDeleteProjectedDate($event)"
                        (onProjectedDateSelect)="onProjectedDateChange($event)"
                        (onBlur)="onProjectedDateChange($event)"
                        [lockableElement]="'job_completed'"
                        [isDisabled]="!job.canEdit || job.jobStatusDescription === 'Closed'"
                        [jobStatusDescription]="job.jobStatusDescription"
                        [cp4State]="job?.controlPoints[3]?.controlPointState?.name">
                      </job-projected-date>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="job-meta-value">
                    <div class="billing-info-group">
                      <a class="billing-info-link disable-except" (click)="billing_info_panel.toggle($event)">View
                        Billing Information</a>
                      <p-overlayPanel #billing_info_panel appendTo="body" styleClass="job-header-billing-info-panel" [loadingIndicator]="loadingSubscription">
                        <ul class="billing-info-list">
                          <li>
                            <strong class="fontSize-custom">Proposal Number: </strong>
                            <span>{{job.proposalNumber}}</span>
                          </li>
                          <li>
                            <strong class="fontSize-custom">Sales Order Number: </strong>
                            <span>{{job.salesOrderNumber}}</span>
                          </li>
                          <li>
                            <strong class="fontSize-custom">Quote Number: </strong> <span>{{job.quoteNumber}}</span>
                          </li>
                          <li>
                            <strong class="fontSize-custom">Ship to: </strong> <span>{{job.shipToNumber}}</span>
                          </li>
                          <li>
                            <strong class="fontSize-custom">Ship to Location: </strong>
                            <span>{{job.shipToLocation}}</span>
                          </li>
                        </ul>
                      </p-overlayPanel>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="job-type cust-mar-bot ui-table">
          <table>
            <thead>
              <tr>
                <th>Job Type</th>
                <th>Job Owner</th>
                <th>Rig</th>
                <th>Job State</th>
                <th>Hole Size</th>
                <th>Casing Size</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{job.jobTypeName}}</td>
                <td>{{job.jobOwner}}</td>
                <td>{{job.rigName}}</td>
                <td [ngClass]="{'state-content': job.jobStatusDescription?.toLowerCase() === 'active'}">
                  {{job.jobStatusDescription}}</td>
                <td>
                  <div class="job-meta-value" [(ngModel)]="job.holeSizeValue" [ngModelOptions]="{standalone: true}"
                       dUnitValue={{UnitType.SmallDistance}} dUnitWithName="true" dUnitDecimalPlaces="3"></div>
                </td>

                <td>
                  <div class="job-meta-value" [(ngModel)]="job.casingSizeValue" [ngModelOptions]="{standalone: true}"
                       dUnitValue={{UnitType.SmallDistance}} dUnitWithName="true" dUnitDecimalPlaces="3"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>

  </ng-template>

  <!--job header with control point options:-->
  <ng-template #jobheader_cp_part>
    <ng-container [ngSwitch]="cpOptions.type">
      <!--control point 1:-->
      <div *ngSwitchCase="1">
        <div class="job-title-group">
          <h2 class="job-title cust-jobName" title="{{job.jobName}}">{{job.jobName}}</h2>
        </div>
        <div class="flx-col-6 flx-col-md-12 link-job">
          <dash-job-originating [job]="job" (onConfirm)="confirmFromModal($event)"></dash-job-originating>
        </div>
        <div class="panel panel-custom job-header-table">
          <div class="panel-body cust-width">
            <div class="cust-mar-bot ui-table">
              <table>
                <thead>
                  <tr>
                    <th class="col-risk-level">Risk Level</th>
                    <th>Job Number</th>
                    <th>Customer</th>
                    <th>Well Name</th>
                    <th>Well Number</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="padding: 1px 6px;">
                      <div>
                        <p-dropdown [options]="riskLevels" formControlName="riskLevel" (onChange)="riskLevelChange($event)"
                                    [disabled]="!job.canEdit"></p-dropdown>
                      </div>
                    </td>
                    <td>{{job.jobCode}}</td>
                    <td>{{job.customerName}}</td>
                    <td>
                      <span>
                        <a [routerLink]="['/wells']" [queryParams]="{id: job.wellId}">{{job.wellName}}</a>
                      </span>
                    </td>
                    <td [ngClass]="['well-number']">{{job.wellNumber}}</td>
                    <td>
                      <div class="job-meta-value">
                        <div class="billing-info-group">
                          <a class="billing-info-link disable-except" (click)="billing_info_panel.toggle($event)">View
                            Billing Information</a>
                          <p-overlayPanel #billing_info_panel appendTo="body"
                                          styleClass="job-header-billing-info-panel">
                            <ul class="billing-info-list">
                              <li>
                                <strong class="fontSize-custom">Proposal Number: </strong>
                                <span>{{job.proposalNumber}}</span>
                              </li>
                              <li>
                                <strong class="fontSize-custom">Sales Order Number: </strong>
                                <span>{{job.salesOrderNumber}}</span>
                              </li>
                              <li>
                                <strong class="fontSize-custom">Quote Number: </strong> <span>{{job.quoteNumber}}</span>
                              </li>
                              <li>
                                <strong class="fontSize-custom">Ship to: </strong> <span>{{job.shipToNumber}}</span>
                              </li>
                              <li>
                                <strong class="fontSize-custom">Ship to Location: </strong>
                                <span>{{job.shipToLocation}}</span>
                              </li>
                            </ul>
                          </p-overlayPanel>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="ui-table">
              <table>
                <thead>
                  <tr>
                    <th style="width: 21%">Job Type</th>
                    <th style="width: 15%">Job Owner</th>
                    <th style="width: 10%">Rig</th>
                    <th style="width: 7%">Job State</th>
                    <th style="width: 12%">Country*</th>
                    <th style="width: 12%"><span *ngIf="isCountryUSA">State*</span></th>
                    <th style="width: 10%"><span *ngIf="isCountryUSA && isLand">Land Type*</span></th>
                    <th style="width: 13%">
                      <ng-container *ngIf="job.actualDate;else projectedDateHeader">
                        Actual Job Date
                      </ng-container>
                      <ng-template #projectedDateHeader>Projected Job Date</ng-template>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{job.jobTypeName}}</td>
                    <td>{{job.jobOwner}}</td>
                    <td>{{job.rigName}}</td>
                    <td [ngClass]="{'state-content': job.jobStatusDescription?.toLowerCase() === 'active'}">
                      {{job.jobStatusDescription}}
                    </td>
                    <ng-container *ngIf="!isEditJobDisabled">
                      <td>
                        <p-dropdown [options]="countries" formControlName="jobCountryId" showClear="true" placeholder="&nbsp;"
                          [lockableElement]="'job_completed'" (onChange)="onCountryChange($event)"></p-dropdown>
                      </td>
                      <td>
                        <p-dropdown *ngIf="isCountryUSA" [options]="states" formControlName="countryStateId" placeholder="&nbsp;"
                          [lockableElement]="'job_completed'" showClear="true"></p-dropdown>
                      </td>
                      <td>
                        <p-dropdown *ngIf="isCountryUSA && isLand" [options]="rigLocationTypes" formControlName="rigLocationTypeId" placeholder="&nbsp;"
                          [lockableElement]="'job_completed'" showClear="true"></p-dropdown>
                      </td>
                    </ng-container>

                    <ng-container *ngIf="isEditJobDisabled">
                      <td>{{getCountryName()}}</td>
                      <td><span *ngIf="isCountryUSA">{{getStateName()}}</span></td>
                      <td><span *ngIf="isCountryUSA && isLand">{{getRigLocationTypeName()}}</span></td>
                    </ng-container>
                    <td>
                      <div class="projected-date">
                        <job-date [jobForm]="jobForm"
                          (onProjectDateChanged)="projectDateChanged($event)">
                        </job-date>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!--control point 2:-->
      <div *ngSwitchCase="2">
        <div class="job-title-group">
          <h2 class="job-title cust-jobName" title="{{job.jobName}}">{{job.jobName}}</h2>
        </div>
        <div class="job-header-table box-shadow ui-table">
          <table>
            <thead>
              <tr>
                <th class="col-risk-level">Risk Level</th>
                <th>Job Number</th>
                <th>Customer</th>
                <th>Well Name</th>
                <th>Well Number</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="cust-risk" [ngClass]="{'cust-risk-red':job.riskLevel>3}">
                  <div>
                    {{job.riskLevel}}
                  </div>
                </td>
                <td>{{job.jobCode}}</td>
                <td>{{job.customerName}}</td>
                <td>
                  <span>
                    <a [routerLink]="['/wells']" [queryParams]="{id: job.wellId}">{{job.wellName}}</a>
                  </span>
                </td>
                <td [ngClass]="['well-number']">{{job.wellNumber}}</td>
                <td>
                  <div class="job-meta-value">
                    <div class="billing-info-group">
                      <a class="billing-info-link disable-except" (click)="billing_info_panel.toggle($event)">View
                        Billing Information</a>
                      <p-overlayPanel #billing_info_panel appendTo="body" styleClass="job-header-billing-info-panel">
                        <ul class="billing-info-list">
                          <li>
                            <strong class="fontSize-custom">Proposal Number: </strong>
                            <span>{{job.proposalNumber}}</span>
                          </li>
                          <li>
                            <strong class="fontSize-custom">Sales Order Number: </strong>
                            <span>{{job.salesOrderNumber}}</span>
                          </li>
                          <li>
                            <strong class="fontSize-custom">Quote Number: </strong> <span>{{job.quoteNumber}}</span>
                          </li>
                          <li>
                            <strong class="fontSize-custom">Ship to: </strong> <span>{{job.shipToNumber}}</span>
                          </li>
                          <li>
                            <strong class="fontSize-custom">Ship to Location: </strong>
                            <span>{{job.shipToLocation}}</span>
                          </li>
                        </ul>
                      </p-overlayPanel>
                    </div>
                  </div>
                </td>
                <td>
                  <span *ngIf="job.hdfJobId">
                    <a *ngIf="proposalNumberHDFLink" href="{{linkToProposalHdf}}{{proposalNumberHDFLink}}" target="_blank" class="billing-info-link">View Linked HDF
                      Job</a>
                    <a *ngIf="!proposalNumberHDFLink" href="{{linkToHDFWithoutProposal}}{{salesOrderNumberHDFLink}}" target="_blank" class="billing-info-link">View Linked HDF Job</a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="ui-table">
            <table>
              <thead>
                <tr>
                  <th class="col-job-tyle">Job Type</th>
                  <th style="width:130px"></th>
                  <th>Sales Order Number</th>
                  <th width="300px">
                    BOM Type
                    <button pButton type="button"
                      icon="pi pi-info-circle info-icon"
                      class="p-button-rounded bom-type-hint-trigger"
                      pTooltip=" Item # - BOM Type (Part Number)"
                      tooltipPosition="top"
                      tooltipEvent="focus"
                      tooltipStyleClass="bom-type-hint">
                    </button>
                  </th>
                  <th>HDF Proposal Number</th>
                  <th>HDF Quote Number</th>
                  <ng-container *ngIf="isCeSalesOrderVisible$ | async">
                    <th>CE Sales Order Number</th>
                    <th>CE covered in primary SO</th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="col-job-tyle">{{job.jobTypeName}}</td>
                  <td>
                    <div class="extract-so" [lockableElement]="'job_completed'" *ngIf="job.hdfJobId && job.quoteNumber && !headerDataForm.value.salesOrderNumber">
                      <a class="link-extract-so" (click)="extractSo()">Extract to SO</a>
                    </div>
                  </td>
                  <td>
                    <div class="job-meta-value sale-order-input" [lockableElement]="'job_completed'">
                      <input class="form-control"
                             maxlength="10"
                             formControlName="salesOrderNumber"
                             placeholder="Enter Sales Order Number"
                             (blur)="autoFillSalesOrderNumber()">
                      <error-display formControlName="salesOrderNumber"></error-display>
                      <input type="hidden" class="form-control" formControlName="shipToNumber">
                      <input type="hidden" class="form-control" formControlName="shipToLocation">
                      <input type="hidden" class="form-control" formControlName="customerId">
                    </div>
                  </td>
                  <td width="300px">
                    <p-blockUI [blocked]="salesOrderBomTypesBlocked" [target]="salesOrderBomTypesPanel">
                      <div class="loading-indicator"></div>
                    </p-blockUI>
                    <div #salesOrderBomTypesPanel>
                      <p-dropdown formControlName="salesOrderLineItemBomType"
                        [options]="salesOrderBomTypesSelectItems"
                        placeholder="Select BOM Type"
                        (onClick)="refreshSalesOrderBomTypes()"
                        [lockableElement]="'job_completed'">
                        <ng-template pTemplate="item" let-item>
                          <div [ngClass]="{ 'bom-type-item': !item.disabled }">{{ item.label }}</div>
                        </ng-template>
                      </p-dropdown>
                      <error-display formControlName="salesOrderLineItemBomType"></error-display>
                    </div>
                  </td>
                  <td>{{job.proposalNumber}}</td>
                  <td>
                    <div class="job-meta-value">{{job.quoteNumber}}</div>
                  </td>
                  <ng-container *ngIf="isCeSalesOrderVisible$ | async">
                    <td>
                      <div class="job-meta-value sale-order-input">
                        <input type="text"
                          formControlName="ceSalesOrderNumber"
                          class="form-control"
                          [placeholder]="ceSalesOrderNumberPlaceholder"
                          (blur)="autoFillSalesOrderNumber(true)">
                        <error-display formControlName="ceSalesOrderNumber"></error-display>
                      </div>
                    </td>
                    <td class="ce-sales-order-origin">
                      <div>
                        <p-radioButton name="ceSoIsPrimarySo" formControlName="ceSoIsPrimarySo" [value]="true" label="Yes" [lockableElement]="'job_completed'"></p-radioButton>
                        <p-radioButton name="ceSoIsPrimarySo" formControlName="ceSoIsPrimarySo" [value]="false" label="No" [lockableElement]="'job_completed'"></p-radioButton>
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="ui-table">
            <table class="any-bom-type">
              <thead>
                <tr>
                  <th width="240px">SO is Not Cementing SO</th>
                  <th>Explanation</th>
                  <th>
                    <ng-container *ngIf="job.actualDate;else projectedDateHeader">
                      Actual Job Date
                    </ng-container>
                    <ng-template #projectedDateHeader>Projected Job Date</ng-template>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="240px" class="align-center">
                    <p-checkbox binary="true" formControlName="allowNonCementingSalesOrder"></p-checkbox>
                  </td>
                  <td class="form-group">
                    <input type="text" class="form-control" formControlName="nonCementingSalesOrderComment" maxlength="255">
                  </td>
                  <td>
                    <div style="max-width: 150px;">
                      <job-date [jobForm]="jobForm"
                        (onProjectDateChanged)="projectDateChanged($event)">
                      </job-date>
                    </div>
                  </td>
                </tr>
              </tbody>
              </table>
          </div>
        </div>
      </div>

      <!--control point 3:-->
      <div *ngSwitchCase="3">
        <div class="job-title-group">
          <h2 class="job-title cust-jobName" title="{{job.jobName}}">{{job.jobName}}</h2>
        </div>
        <div class="panel panel-custom job-header-table">
          <div class="panel-body cust-width">
            <div class="cust-mar-bot ui-table">
              <table>
                <thead>
                  <tr>
                    <th class="col-risk-level">Risk Level</th>
                    <th>Job Number</th>
                    <th>Customer</th>
                    <th>Well Name</th>
                    <th>Well Number</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="cust-risk" [ngClass]="{'cust-risk-red':job.riskLevel>3}">
                      {{job.riskLevel}}
                    </td>
                    <td>{{job.jobCode}}</td>
                    <td>{{job.customerName}}</td>
                    <td>
                      <span>
                        <a [routerLink]="['/wells']" [queryParams]="{id: job.wellId}">{{job.wellName}}</a>
                      </span>
                    </td>
                    <td [ngClass]="['well-number']">{{job.wellNumber}}</td>
                    <td>
                      <div class="job-meta-value">
                        <div class="billing-info-group">
                          <a class="billing-info-link disable-except" (click)="billing_info_panel.toggle($event)">View
                            Billing Information</a>
                          <p-overlayPanel #billing_info_panel appendTo="body"
                                          styleClass="job-header-billing-info-panel">
                            <ul class="billing-info-list">
                              <li>
                                <strong class="fontSize-custom">Proposal Number: </strong>
                                <span>{{job.proposalNumber}}</span>
                              </li>
                              <li>
                                <strong class="fontSize-custom">Sales Order Number: </strong>
                                <span>{{job.salesOrderNumber}}</span>
                              </li>
                              <li>
                                <strong class="fontSize-custom">Quote Number: </strong> <span>{{job.quoteNumber}}</span>
                              </li>
                              <li>
                                <strong class="fontSize-custom">Ship to: </strong> <span>{{job.shipToNumber}}</span>
                              </li>
                              <li>
                                <strong class="fontSize-custom">Ship to Location: </strong>
                                <span>{{job.shipToLocation}}</span>
                              </li>
                            </ul>
                          </p-overlayPanel>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="controlpoin3 ui-table">
              <table>
                <thead>
                  <tr>
                    <th class="col-job-tyle">Job Type</th>
                    <th>Rig</th>
                    <th>Job State</th>
                    <th>Sales Order Number</th>
                    <th>Ship to</th>
                    <th>Actual Job Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{job.jobTypeName}}</td>
                    <td>{{job.rigName}}</td>
                    <td [ngClass]="{'state-content': job.jobStatusDescription?.toLowerCase() === 'active'}">
                      {{job.jobStatusDescription}}</td>
                    <td>{{job.salesOrderNumber}}</td>
                    <td>{{job.shipToNumber}}</td>
                    <td style="width: 220px">
                      <div style="width: 140px">
                        <datetime-picker (onClear)="onActualDateClearCP3()"
                              class="exception-disable-item disable-except"
                              format="{{userSettings.dateTimeFormat.dateTimeFormatName}}"
                              formControlName="actualDate"
                              [placeholder]="userSettings.dateTimeFormat.dateTimeFormatName"
                              [showAlert]="!isActualDateValid || isActualDateRequired"
                              (change)="onActualDateChangeCP3($event)"
                              [lockableElement]="'job_completed'"
                              [maxDate]="today"
                              [defaultTime]="actulJobDateDefaultTime">
                        </datetime-picker>
                      </div>
                      <span class="error-msg" *ngIf="!isActualDateValid">Actual date cannot be in the future</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!--control point 4:-->
      <div *ngSwitchCase="4">
        <div class="job-title-group">
          <h2 class="job-title cust-jobName" title="{{job.jobName}}">{{job.jobName}}</h2>
        </div>
        <div class="panel panel-custom job-header-table">
          <div class="panel-body cust-width">
            <div class="cust-mar-bot ui-table">
              <table>
                <thead>
                  <tr>
                    <th class="col-risk-level">Risk Level</th>
                    <th>Job Number</th>
                    <th>Customer</th>
                    <th>Well Name</th>
                    <th>Well Number</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="risk-level-cp4" [ngClass]="{'red-bg':job.riskLevel>3}">
                      {{job.riskLevel}}
                    </td>
                    <td class="vertical-align-middle">{{job.jobCode}}</td>
                    <td class="vertical-align-middle">{{job.customerName}}</td>
                    <td class="vertical-align-middle">
                      <span>
                        <a [routerLink]="['/wells']" [queryParams]="{id: job.wellId}">{{job.wellName}}</a>
                      </span>
                    </td>
                    <td class="vertical-align-middle" [ngClass]="['well-number']">{{job.wellNumber}}</td>
                    <td class="vertical-align-middle">
                      <div class="job-meta-value">
                        <div class="billing-info-group">
                          <a class="billing-info-link disable-except" (click)="billing_info_panel.toggle($event)">View
                            Billing Information</a>
                          <p-overlayPanel #billing_info_panel appendTo="body"
                                          styleClass="job-header-billing-info-panel">
                            <ul class="billing-info-list">
                              <li>
                                <strong class="fontSize-custom">Proposal Number: </strong>
                                <span>{{job.proposalNumber}}</span>
                              </li>
                              <li>
                                <strong class="fontSize-custom">Sales Order Number: </strong>
                                <span>{{job.salesOrderNumber}}</span>
                              </li>
                              <li>
                                <strong class="fontSize-custom">Quote Number: </strong> <span>{{job.quoteNumber}}</span>
                              </li>
                              <li>
                                <strong class="fontSize-custom">Ship to: </strong> <span>{{job.shipToNumber}}</span>
                              </li>
                              <li>
                                <strong class="fontSize-custom">Ship to Location: </strong>
                                <span>{{job.shipToLocation}}</span>
                              </li>
                            </ul>
                          </p-overlayPanel>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="ui-table">
              <table>
                <thead>
                  <tr>
                    <th>Job Type</th>
                    <th>Rig</th>
                    <th>Job State</th>
                    <th>Sales Order Number* </th>
                    <th width="300px">
                      BOM Type*
                      <button pButton type="button"
                        icon="pi pi-info-circle info-icon"
                        class="p-button-rounded bom-type-hint-trigger"
                        pTooltip=" Item # - BOM Type (Part Number)"
                        tooltipPosition="top"
                        tooltipEvent="focus"
                        tooltipStyleClass="bom-type-hint">
                      </button>
                    </th>
                    <ng-container *ngIf="isCeSalesOrderVisible$ | async">
                      <th>CE Sales Order Number</th>
                      <th>CE covered in primary SO</th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{job.jobTypeName}}</td>
                    <td>{{job.rigName}}</td>
                    <td [ngClass]="{'state-content': job.jobStatusDescription?.toLowerCase() === 'active'}">
                      {{job.jobStatusDescription}}</td>
                    <td>
                      <div class="job-meta-value sale-order-input" [lockableElement]="'cp4_saleOrderNumber'">
                        <input class="form-control disable-except"
                               [pKeyFilter]="salesOrderNumberKeyFilter"
                               maxlength="10"
                               formControlName="salesOrderNumber"
                               placeholder="Enter Sales Order Number"
                               (blur)="autoFillSalesOrderNumber()">
                        <error-display formControlName="salesOrderNumber"></error-display>
                      </div>
                    </td>
                    <td width="300px" class="blockable">
                      <p-blockUI [blocked]="salesOrderBomTypesBlocked" [target]="salesOrderBomTypesPanel">
                        <div class="loading-indicator"></div>
                      </p-blockUI>
                      <p-panel #salesOrderBomTypesPanel>
                        <p-dropdown formControlName="salesOrderLineItemBomType"
                          [options]="salesOrderBomTypesSelectItems"
                          placeholder="Select BOM Type"
                          (onClick)="refreshSalesOrderBomTypes()"
                          (onChange)="onBomTypeChange()"
                          [lockableElement]="'cp4_saleOrderNumber'">
                          <ng-template pTemplate="item" let-item>
                            <div [ngClass]="{ 'bom-type-item': !item.disabled }">{{ item.label }}</div>
                          </ng-template>
                        </p-dropdown>
                        <error-display formControlName="salesOrderLineItemBomType"></error-display>
                      </p-panel>
                    </td>
                    <ng-container *ngIf="isCeSalesOrderVisible$ | async">
                      <td>
                        <div class="job-meta-value sale-order-input">
                          <input type="text"
                            formControlName="ceSalesOrderNumber"
                            class="form-control"
                            [placeholder]="ceSalesOrderNumberPlaceholder"
                            (blur)="autoFillSalesOrderNumber(true)">
                          <error-display formControlName="ceSalesOrderNumber"></error-display>
                        </div>
                      </td>
                      <td class="ce-sales-order-origin">
                        <div>
                          <p-radioButton name="ceSoIsPrimarySo" formControlName="ceSoIsPrimarySo" [value]="true" label="Yes" [lockableElement]="'job_completed'"></p-radioButton>
                          <p-radioButton name="ceSoIsPrimarySo" formControlName="ceSoIsPrimarySo" [value]="false" label="No" [lockableElement]="'job_completed'"></p-radioButton>
                        </div>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row job-header-actual-row">
        <div class="panel-custom col-sm-6 cus-sm-6">
        <div class="panel-body cust-width">
          <div class="cust-mar-bot ui-table">
          <table >
            <thead>
              <tr>
                <th width="150px">SO is Not Cementing SO</th>
                <th>Explanation<span *ngIf="explanationRequired">*</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="150px" class="align-center">
                  <p-checkbox binary="true" formControlName="allowNonCementingSalesOrder"></p-checkbox>
                </td>
                <td class="form-group">
                  <input type="text" class="form-control" formControlName="nonCementingSalesOrderComment" maxlength="255">
                </td>
              </tr>
            </tbody>
            </table>
          </div></div></div>
        <div class="panel-custom col-sm-6 cus-sm-6">
        <div class="panel-body cust-width">
          <div class="cust-mar-bot ui-table">
            <table>
              <thead>
                <tr>
                  <th colspan="2">Remedial work required after job completion?</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="95px">
                    <p-dropdown appendTo="body" [options]="optionsRemdialWorkRequired" [style]="{'max-width':'95px'}"
                    formControlName="isRemedialWorkRequired" (onChange)="updateFlagRemedialWorkRequired()"
                    [disabled]="!(job.jobStatusDescription === 'Active' || job.jobStatusDescription === 'Completed' || job.jobStatusDescription === 'Closed')"></p-dropdown>
                  </td>
                  <td >
                    <input  maxlength="255" class="form-control"
                     formControlName="remedialWorkRequiredComments" type="text"
                     [disabled]="!(job.jobStatusDescription === 'Active' || job.jobStatusDescription === 'Completed' || job.jobStatusDescription === 'Closed')">
                  </td>
                </tr>
              </tbody>
            </table>
        </div></div></div></div>
        <div class="row job-header-actual-row">
          <div class="panel panel-custom col-sm-3 cus-sm-3">
            <div class="panel-body cust-width">
              <div class="cust-mar-bot ui-table">
                <table>
                  <thead>
                    <tr class="stage-info-header">
                      <th class="header-width">Planned Job MD</th>
                      <th>Actual Job MD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="stage-info-body">
                      <td class="vertical-align-middle">
                        <div class="float-left flx-ctn-row m-r-10">
                          <span formControlName="jobMd" dUnitValue={{UnitType.Distance}} dUnitWithName="true"></span>
                        </div>
                      </td>
                      <td class="padding-box">
                        <div class="flx-ctn-row">
                          <input-unit styleClass="none-border-radius" class="flx-itm-grow m-r-10"
                                      formControlName="actualJobMD" unit={{UnitType.Distance}} [lockableElement]="'cp4_actualMd'"></input-unit>
                          <span class="flx-itm-center" [unitType]="UnitType.Distance"></span>
                          <error-display formControlName="actualJobMD"></error-display>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="panel panel-custom col-sm-3 cus-sm-3">
            <div class="panel-body cust-width">
              <div class="ui-table" [ngClass]="controlpointType == 4 ? 'cust-mar-bot-cp4' : 'cust-mar-bot'">
                <table>
                  <thead>
                    <tr>
                      <th class="header-width">Projected Job Date</th>
                      <th>Actual Job Date* </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="vertical-align-middle">
                        {{job.projectedDate | date: DATE_TIME_CALL_STANDARD }}
                      </td>
                      <td class="col-sale-order-number vertical-align-middle">
                        <div class="row flx-ctn-row" style="width: 100%">
                          <div class="col-md-10" style="width: 99%">
                            <datetime-picker (onClear)="onActualDateClear()"
                                             class="exception-disable-item disable-except"
                                             format="{{userSettings.dateTimeFormat.dateTimeFormatName}}"
                                             formControlName="actualDate"
                                             [placeholder]="userSettings.dateTimeFormat.dateTimeFormatName"
                                             [showAlert]="!isActualDateValid || isActualDateRequired"
                                             (change)="onActualDateChange($event)"
                                             [maxDate]="today"
                                             [lockableElement]="'cp4_acutalJobDate'"
                                             [defaultTime]="actulJobDateDefaultTime">
                            </datetime-picker>
                            <span class="error-msg" *ngIf="!isActualDateValid">Actual date cannot be in the future</span>
                            <span class="error-msg" *ngIf="isActualDateRequired">Actual date required</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>



  <!-- ------------------------------------------------------ -->
  <div *ngIf="!collapsed" id="anc" class="show-more-div">
    <div class="panel panel-custom">
      <div class="panel-body cust-width1">
        <div class="cust-mar-bot1 ui-table">
          <table>
            <thead>
              <tr>
                <th>Well Datum Elevation</th>
                <th>Job MD</th>
                <th>Job TVD</th>
                <th>Water Depth</th>
                <th>Offshore Flag</th>
                <th>Deviation Status</th>
                <th>Group</th>
                <th>BOM Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="job-meta-value" [(ngModel)]="job.wellDatumElevation" [ngModelOptions]="{standalone: true}"
                       dUnitValue={{UnitType.Distance}} dUnitWithName="true"></div>
                </td>
                <td>
                  <div class="job-meta-value" [(ngModel)]="job.jobMd" [ngModelOptions]="{standalone: true}"
                       dUnitValue={{UnitType.Distance}} dUnitWithName="true"></div>
                </td>
                <td>
                  <div class="job-meta-value" [(ngModel)]="job.jobTvd" [ngModelOptions]="{standalone: true}"
                       dUnitValue={{UnitType.Distance}} dUnitWithName="true"></div>
                </td>
                <td>
                  <div class="job-meta-value" [(ngModel)]="job.waterDepth" [ngModelOptions]="{standalone: true}"
                       dUnitValue={{UnitType.Distance}} dUnitWithName="true"></div>
                </td>
                <td>
                  <div class="job-meta-value" *ngIf="!job.isOffshore; else offshoreFalsy">
                    Land
                  </div>
                  <ng-template #offshoreFalsy>
                    <div class="job-meta-value">Offshore</div>
                  </ng-template>

                </td>
                <td>

                  <div class="job-meta-value" *ngIf="!job.isDeviated; else deviatedFalsy">
                    Vertical
                  </div>
                  <ng-template #deviatedFalsy>
                    <div class="job-meta-value">Deviated</div>
                  </ng-template>
                </td>
                <td>
                  <div class="job-meta-value right-overflow">{{job.regionCode + ' - ' + job.groupName}}</div>
                </td>
                <td>
                  <div class="job-meta-value right-overflow">{{job.bomTypeDescription}}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bodytable ui-table">
          <table>
            <thead>
              <tr>
                <!-- <th>Well Number</th> -->
                <th>Lease</th>
                <th>Field</th>
                <th>Site</th>
                <th>Well LAT/LON</th>
                <th>Well API/UWI</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <!-- <td>
                  <div class="job-meta-value" [ngClass]="['well-number']">{{job.wellNumber}}</div>
                </td> -->
                <td>
                  <div class="job-meta-value">{{job.lease}}</div>
                </td>
                <td>
                  <div class="job-meta-value">{{job.field}}</div>
                </td>
                <td>
                  <div class="job-meta-value">{{job.site}}</div>
                </td>
                <td>
                  <div class="job-meta-value">
                    {{job.latitudeDegrees}}<span *ngIf="checkNumber(job.latitudeDegrees)">&#176;</span>
                    {{job.latitudeMinutes}}<span *ngIf="checkNumber(job.latitudeMinutes)">'</span>
                    {{job.latitudeSeconds}}<span *ngIf="checkNumber(job.latitudeSeconds)">"</span>
                    <span
                          *ngIf="checkNumber(job.latitudeDegrees) || checkNumber(job.latitudeMinutes) || checkNumber(job.latitudeSeconds)">{{job.latitudeDirection}}</span>
                    <span
                          *ngIf="(checkNumber(job.latitudeDegrees) || checkNumber(job.latitudeMinutes) || checkNumber(job.latitudeSeconds)) && (checkNumber(job.longitudeDegrees) || checkNumber(job.longitudeMinutes) || checkNumber(job.longitudeSeconds))">/</span>
                    {{job.longitudeDegrees}}<span *ngIf="checkNumber(job.longitudeDegrees)">&#176;</span>
                    {{job.longitudeMinutes}}<span *ngIf="checkNumber(job.longitudeMinutes)">'</span>
                    {{job.longitudeSeconds}}<span *ngIf="checkNumber(job.longitudeSeconds)">"</span>
                    <span
                          *ngIf="checkNumber(job.longitudeDegrees) || checkNumber(job.longitudeMinutes) || checkNumber(job.longitudeSeconds)">
                      {{job.longitudeDirection}}</span>
                  </div>
                </td>
                <td>
                  <div class="job-meta-value">{{job.wellApiUwi}}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
