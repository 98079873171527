import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, BehaviorSubject, Subscription, Subject, EMPTY } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { distinctUntilChanged, map, tap, filter, takeUntil, switchMap } from 'rxjs/operators';
import { ISAPMaterialModel } from 'libs/models';
import { MaterialService, MasterDataService, IfactService, ApplicationStateService, JobsService, JobCasingEquipmentService } from 'libs/shared/services';
import { ColumnDefinition } from 'libs/ui/models/column-definition';
import { SlurrySource } from 'libs/constants';
import { CEMaterialAutocompleteProvider, iFactsMaterialAutocompleteProvider, ISAPMaterialAutocompleteProvider } from './sap-material-autocomplete-providers';
import { AutoComplete } from 'primeng/autocomplete';

@Component({
  selector: 'sap-material-autocomplete',
  template: `
    <p-autoComplete #autoCompleteMaterial
                    [multiple]="true"
                    [suggestions]="_suggestedMaterials"
                    (completeMethod)="onCompleteMaterial($event.query)"
                    (onSelect)="_onSelectSAP($event)"
                    (onUnselect)="_onSelectSAP($event)"
                    [(ngModel)]="_materialList"
                    field="label"
                    [disabled]="disabled">
    </p-autoComplete>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['sap-material.autocomplete.component.scss'],
})
export class SapMaterialAutocompleteComponent implements OnChanges, OnDestroy, OnInit {

  @Input() isIfactFirst: boolean;
  @Input() isCEMaterial: boolean = false;
  @Input() styleClass: string;
  @Input() slurrySource: SlurrySource;
  @Input() useTextArea: boolean;
  @Input() disabled: boolean;
  @Input() sapNumberFormControl: UntypedFormControl;
  @Input() sapNumberDisplayFormat: string = null;
  @Output() onChangeSAP = new EventEmitter<any>();
  @Output() onClearSAP = new EventEmitter<ISAPMaterialModel>();
  @Output() onKeyupSAP = new EventEmitter();
  @Output() onUpdateDisplayName = new EventEmitter<string>();
  @ViewChild('autoCompleteMaterial') autoCompleteMaterial! : AutoComplete; 

  _columns: ColumnDefinition[] = [
    new ColumnDefinition('id', '', false),
    new ColumnDefinition('materialName', 'SAP Material Name'),
    new ColumnDefinition('materialNumber', 'SAP Material Number'),
  ];
  _materialList: any []; 
  _suggestedMaterials: any []; 
  _sapMaterials: ISAPMaterialModel[];
  _sapMaterialsIfact: ISAPMaterialModel[];
  _isSearching: boolean;
  _focus$ = new BehaviorSubject<any>(null);
  private _unsubscribe$ = new Subject<void>();
  _mappingSubscription: Subscription;
  private _materialProvider: ISAPMaterialAutocompleteProvider;

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _materialService: MaterialService,
    private _masterDataService: MasterDataService,
    private _ifactService: IfactService,
    private _appState: ApplicationStateService,
    private _jobsService: JobsService,
    private _ceService: JobCasingEquipmentService

  ) {
    
  }

  ngOnInit(): void {
    this._materialProvider = this.isCEMaterial ? new CEMaterialAutocompleteProvider(this._ceService) :
          new iFactsMaterialAutocompleteProvider(this._materialService, this._ifactService, this._masterDataService);

    this._updateSAPMaterialDisplayName();
    this._lookupSAPMaterialNameByNumber();
    if( !!this.sapNumberFormControl.value ) {
      this._materialList = this.sapNumberFormControl.value;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const { sapMaterialNumber, sapMaterialName } = changes;    
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  onCompleteMaterial(search) {
    /*this._suggestedMaterials = this._materialList.filter(res => {
      if (res.label)
        return res.label.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });*/
    this._onLazyLoadSAP(search)
  }

  _lookupSAPMaterialNameByNumber() {
    
  }

  _updateSAPMaterialDisplayName() {
    
  }

  _getDisplayName(sapMaterialNumber, sapMaterialName: string) {
    if (!sapMaterialNumber || !sapMaterialName) {
      return '';
    }
    return this._formatDisplayName(sapMaterialNumber, sapMaterialName);
  }

  _formatDisplayName(materialNumber: string, materialName: string): string {
    let result = this.sapNumberDisplayFormat;
    if (result) {
      const shortSAPNumber: number = parseFloat(materialNumber);
      result = result.replace('[number]', materialNumber);
      result = result.replace('[shortnumber]', shortSAPNumber.toString());
      result = result.replace('[name]', materialName);
    } else {
      result = `${ materialName } (${ materialNumber })`;
    }
    return result;
  }

  _onLazyLoadSAP(keyword): void {
    if (keyword) {
      this._materialProvider.searchMaterials(keyword).pipe(takeUntil(this._unsubscribe$))
        .subscribe(res => {
          this._sapMaterials = [...res];
          this._cdRef.markForCheck();
          this._suggestedMaterials = this._sapMaterials.map(lab => {
            return {
              label: lab.materialName+' ('+ parseInt(lab.materialNumber, 10)+')',
              value: lab.materialNumber
            };
          });
        });
    }
  }

  _onKeyupSAP() {
    this._isSearching = true;
    this.onKeyupSAP.emit();
  }

  _onChangeSAP(sapMaterial: ISAPMaterialModel) {
  }

  _onSelectSAP(sapMaterial: any) {
    if(this._materialList.length === 0){
      this.sapNumberFormControl.setValue(null);
      this.onClearSAP.emit(null);
    } else{
      this.sapNumberFormControl.setValue(this._materialList.map(m => m.value ));
      this.onChangeSAP.emit({sapNumbers:this._materialList.map(m => m.value ), sapNumberSelection:this._materialList});
    }
  }

  _onFocusSAP(event) {
    // // When clicking on the input it will fire click event, for some reason, the click event is fired on overlaypant later than focus event here.
    // // The code below is a HACK to avoid the overlay disappear due to misleading click event.
    // // https://github.com/primefaces/primeng/blob/6.1.7/src/app/components/overlaypanel.ts#L82
    this._focus$.next(event);
  }

  _onFocusOutSAP(e) {
    if (this._mappingSubscription)
      this._mappingSubscription.unsubscribe();
  }

  _onClearSAP(event) {
    this._displayAvaiableOptions();
    this.onClearSAP.emit(event);
  }

  _displayAvaiableOptions() {
  }

  _findMappingForSAPMaterial$ = (generalMaterialId: number): Observable<ISAPMaterialModel[]> => {
    if (!generalMaterialId) return of([]);

    let groupId = this._jobsService.groupId$.value;
    if (this.isIfactFirst) {
      groupId = null;
    }

    if (groupId && this._sapMaterialsIfact !== undefined)
      return of(this._sapMaterialsIfact);

    return this._materialProvider.getSapMaterialDetails(generalMaterialId, groupId).pipe(
      tap(mappings => this._sapMaterialsIfact = mappings)
    );
  }
}

