import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

import { FluidModel } from 'libs/models';
import { map, delay, tap } from 'rxjs/operators';
import { Subscription, forkJoin } from 'rxjs';

import { DynamicSidebarContent, DynamicSidebarRef, PDropDownModel } from 'libs/ui';
import { PDropdownDataSanitizerHelper } from '../../../shared/helpers';
import { SlurrySource, HELP_FILES } from 'libs/constants';
import { MasterDataService, JobService } from 'libs/shared/services';
import { environment } from 'libs/environment';

export interface IfactLinkedFluidsSidebarInput {
  jobId: string;
}

@Component({
  selector: 'ifact-linked-fluids-sidebar',
  templateUrl: './ifact-linked-fluids-sidebar.component.html',
  styleUrls: ['./ifact-linked-fluids-sidebar.component.scss']
})
export class IfactLinkedFluidsSidebarComponent implements DynamicSidebarContent, OnInit {
  data: IfactLinkedFluidsSidebarInput = {
    jobId: null
  };
  fluids: FluidModel[] = [];
  slurryTypes: PDropDownModel[] = [];
  testTypes: PDropDownModel[] = [];
  primaryStatuses: any[] = [];
  SlurrySource = SlurrySource;
  loadingSubscription: Subscription;

  constructor(
    private dynamicSidebarRef: DynamicSidebarRef<IfactLinkedFluidsSidebarComponent>,
    private jobService: JobService,
    private masterDataService: MasterDataService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.loadFluids(this.data.jobId);
    this.loadingSubscription = forkJoin(
      this.masterDataService.listSlurryTypes().pipe(
        map(PDropdownDataSanitizerHelper('name', 'id'))
      ),
      this.masterDataService.listTestTypes().pipe(
        map(PDropdownDataSanitizerHelper('name', 'id'))
      ),
      this.masterDataService.listPrimaryStatuses().pipe(
        map(PDropdownDataSanitizerHelper('name', 'id'))
      )
    ).subscribe(([slurryTypes, testTypes, primaryStatuses]) => {
      this.slurryTypes = slurryTypes as PDropDownModel[];
      this.testTypes = testTypes as PDropDownModel[];
      this.primaryStatuses = primaryStatuses as PDropDownModel[];
    });
  }

  close() {
    this.dynamicSidebarRef.close();
  }

  loadFluids(jobId): void {
    this.loadingSubscription = this.jobService.getFluidSummary(jobId, true).subscribe(fluids => {
      const fluidsLinks = fluids.filter(x => x.slurrySource === SlurrySource.LinkedFluid || x.slurrySource === SlurrySource.ManualFluid || x.slurrySource === SlurrySource.HDFFluid).map(fluid => {
        fluid.iconStatus = fluid.primaryStatusId && fluid.primaryStatusId.toUpperCase() === '9DE995FA-3F71-44CA-A745-B93C7C57A9DB' ? 'P' : '';
        return fluid;
      });
      this.fluids = fluidsLinks;
    });

  }

  updateTestTypes(event, i) {
    this.fluids[i].testTypeId = event.value;
    var testType = this.testTypes.find(x => x.value == event.value);
    if (testType) {
      this.fluids[i].testType = testType.label;
    }
  }

  updateSlurryTypes(event, i) {
    this.fluids[i].slurryTypeId = event.value;
    var slurryType = this.slurryTypes.find(x => x.value == event.value);
    if (slurryType) {
      this.fluids[i].slurryType = slurryType.label;
    }
  }

  updatePrimaryStatuses(event, i) {
    this.fluids[i].primaryStatusId = event.value;
  }

  updateSlurries() {
    const requestModel = this.fluids.map(request => {
      return {
        id: request.id,
        requestId: request.requestId,
        slurryNo: request.slurryNo,
        primaryStatusId: request.primaryStatusId,
        slurryTypeId: request.slurryTypeId,
        slurryId: request.slurryId,
        slurryType: request.slurryType,
        usedFor: 0,
        testTypeId: request.testTypeId,
        testType: request.testType
      };
    });

    this.loadingSubscription = this.jobService.linkFluids(this.data.jobId, requestModel).pipe(
      delay(1500), tap(() => { this.dynamicSidebarRef.close(true) }),
    ).subscribe(data => {
      this.messageService.clear();
      this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: 'Fluids Summary Settings has been saved successfully.' });
    });
  }

  openHelp() {
    window.open(HELP_FILES.Edit_Fluids);
  }
}


