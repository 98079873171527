import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import { MsalGuard, MsalModule, MsalInterceptor, MsalRedirectComponent } from '@azure/msal-angular';
import { Configuration, InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ClickOutsideModule } from 'ng-click-outside';

import { CPESharedModule } from '../shared/shared.module';
import { AppComponent, NotFoundComponent, LeftNavComponent, UserSettingComponent, AccessDeniedComponent, UnderConstructionComponent } from './components';
import { environment } from 'libs/environment';
import { UIModule } from 'libs/ui';
import { NavigationMenuModule } from './../navigation-menu/navigation-menu.module';
import { CollectionSidebarComponent } from '../collection/components';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { PumpScheduleModule } from '../pump-schedule/pump-schedule.module';
import { PumpModule } from '../pump/pump.module';
import { FluidsModule } from '../fluids/fluids.module';
import { VIDASharedModule } from 'libs/shared/vida-shared.module';
import { CollectionService, WellService, SessionService, UserSettingService, RoleService, JobStateService, appLoadingFactory, FileService, AdminService } from 'libs/shared/services';
import { IFactInterceptor, RequestQueueInterceptor, IERequestQueueInterceptor } from 'libs/shared/interceptors';
import { MailService } from 'libs/shared/services/mail.service';
import { ActiveJobsModule } from '../active-jobs/active-jobs.module';
import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NgSelectModule } from '@ng-select/ng-select';
import { DWXService } from '../well/services';
import { CarbonFootprintModule } from '../carbon-footprint/carbon-footprint.module';
import { TimezoneInterceptor } from 'libs/shared/interceptors/timezone.interceptor';
import { ApplicationInsightsErrorHandler } from 'libs/shared/services/app-insights-error-handler.service';
import { EnvanaDownInterceptor } from 'libs/shared/interceptors/envana-down.interceptor';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';

export const routes: Routes = [
  { path: '', redirectTo: 'active-jobs', pathMatch: 'full' },
  { path: 'job-simple', loadChildren: () => import('./../jobs/job-dashboard-simple.module').then(m => m.JobDashboardSimpleModule), canActivate: [MsalGuard] },
  { path: 'active-jobs', loadChildren: () => import('./../active-jobs/active-jobs.module').then(m => m.ActiveJobsModule), canActivate: [MsalGuard] },
  { path: 'wells', loadChildren: () => import('./../well/well.module').then(m => m.WellModule), canActivate: [MsalGuard] },
  { path: 'my-jobs', loadChildren: () => import('./../my-jobs/my-jobs.module').then(m => m.MyJobsModule), canActivate: [MsalGuard] },
  { path: 'saved-jobs', loadChildren: () => import('./../saved-jobs/saved-jobs.module').then(m => m.SavedJobsModule), canActivate: [MsalGuard] },
  { path: 'search-results', loadChildren: () => import('./../search-results/search-results.module').then(m => m.SearchResultsModule), canActivate: [MsalGuard] },
  { path: 'collections', loadChildren: () => import('./../collection/collection.module').then(m => m.CollectionModule), canActivate: [MsalGuard] },
  { path: 'control-point', loadChildren: () => import('./../control-point/control-point.module').then(m => m.ControlPointModule), canActivate: [MsalGuard] },
  { path: 'report', loadChildren: () => import('./../report/report.module').then(m => m.ReportModule), canActivate: [MsalGuard] },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: 'under-construction', component: UnderConstructionComponent },
  { path: '**', component: NotFoundComponent }
];

export const preloadingServices = [
  SessionService,
  MailService,
  UserSettingService,
  UnitConversionService,
  RoleService,
  JobStateService,
  CollectionService,
  WellService,
  DWXService
];

export const preloadingServicesForAppLoading = [
  SessionService,
  UserSettingService,
  UnitConversionService,
  RoleService,
  JobStateService,
  PublicClientApplication,
  AdminService,
  FileService
];

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const azureAdAppConfig: Configuration = {
  auth: {
    clientId: environment.azureAd.clientId,
    authority: `https://login.microsoftonline.com/${environment.azureAd.tenantId}`,
    redirectUri: environment.azureAd.redirectUri
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE
  }
};

const azureAdApp = new PublicClientApplication(azureAdAppConfig);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    UnderConstructionComponent,
    LeftNavComponent,
    UserSettingComponent,
    AccessDeniedComponent,
    CollectionSidebarComponent
  ],
  exports: [],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ActiveJobsModule, // Preload this module because it impacts to first login experience.
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    DropdownModule,
    SidebarModule,
    ToastModule,
    InputSwitchModule,
    ClickOutsideModule,
    CPESharedModule.forRoot(),
    UIModule.forRoot(),
    VIDASharedModule.forRoot(),
    NavigationMenuModule,
    PumpScheduleModule.forRoot(),
    CarbonFootprintModule.forRoot(),
    PumpModule.forRoot(),
    FluidsModule.forRoot(),
    MsalModule.forRoot(azureAdApp, {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read']
      }
    }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
        [`${environment.baseUrl}/HTCOMNET`, null],
        [`${environment.baseUrl}`, [`${environment.azureAd.resource}/VIDA`]]
      ])
    }),
    RouterModule.forRoot(routes, {}),
    NgxMaskPipe,
    NgxMaskDirective,
    NgSelectModule],
  providers: [
    provideEnvironmentNgxMask(),
    MessageService,
    MsalGuard,
    preloadingServices,
    {
      provide: APP_BASE_HREF,
      useFactory: baseLocationFactory
    },
    {
      provide: PublicClientApplication,
      useValue: azureAdApp
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appLoadingFactory,
      deps: [...preloadingServicesForAppLoading],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IFactInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestQueueInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IERequestQueueInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimezoneInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EnvanaDownInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationInsightsErrorHandler
    }
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CacheInterceptor,
    //   multi: true
    // },
    ,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class ApplicationModule {
}

export function baseLocationFactory() {
  // TODO: move to environment setting
  const keyclockClientId = 'vida';

  const firstCurrentPath = window.location.pathname.split('/')[1] || '';
  if (firstCurrentPath === keyclockClientId) {
    return '/' + firstCurrentPath;
  }

  return '/';
}
