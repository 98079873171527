export class AdUser {
  email = '';
  mobilePhoneNumber = '';
  firstName = ''
  lastName = ''
  networkDomain = ''
  networkId = ''
  display = ''
  constructor() { }

  get displayName(): string {
    return `${this.firstName} ${this.lastName} (${this.networkId})`;
  }
  get displayNameGroup(): string {
    return `${this.lastName} ${this.firstName} (${this.networkId})`;
  }
}
