<div *ngIf="editJobAdapter.fluidForm$ | async as fluidForm" 
  [formGroup]="fluidForm"
  [loadingIndicator]="loadingSubscription"
  [ngClass]="{'disable-state': jobStateCancel}"
>
  <div class="row form-header-section">
    <div class="col-sm-7 form-header-section">
      <div class="fluid-title">
        <span>Fluids</span>
      </div>
    </div>
    <div class="col-sm-5">
      <heading-element 
        type="fluid" 
        [canEdit]="job.canEdit" 
        (onShowIfactSearchPage)='showIfactSearchPage()'
        (onShowFluidSettings)='showFluidSettings($event)' 
        (onShowFluidEditParameter)='showFluidSettings($event)'
        (onAddNewFluid)='addNewFluid($event)'  
      ></heading-element>
    </div>
  </div>
  <div *ngIf="!fluidForm['controls']?.slurry['controls'].length">No data.</div>

  <div *ngFor="let fluidFormGroup of fluidForm['controls']?.slurry['controls']; let i = index">
    <fluid-detail 
      #fluidDetail 
      [fluidFormGroup]="fluidFormGroup" 
      [index]="i + 1" 
      [isFoamJob]="job.isFoam"  
      [canEdit]="job.canEdit" 
      [concentrationUnitList]="concentrationUnitList"
      (deleteFluidDetail)="deleteFluid(i)" 
      (copyRequest)="copyRequest(i)" 
      (createRequest)="createRequest(i)" 
      [job]="job"
    >
    </fluid-detail>
  </div>
</div>
