<p-sidebar [(visible)]="visible"
           [dismissible]="dismissible"
           [autoZIndex]="false"
           [styleClass]="className"
           [showCloseIcon]="showCloseIcon"
           position="right"
           blockScroll="true"
           appendTo="body"
           modal="true">
  <ng-template dynamic-content></ng-template>
</p-sidebar>
<!-- [autoZIndex]="false" is replaced with [baseZIndex]="1050"
to show the overlay mask behind the sidebar on top of other modals
previous commit aa2a1349b540ce07bac9e85d96745018fc6d3e25 -->
