import { Component, ViewChild, ComponentFactoryResolver, EventEmitter } from '@angular/core';

import { Subject, timer } from 'rxjs';

import { DynamicContentDirective } from '../../directives';
import { DynamicSidebarContent } from './dynamic-sidebar-content.model';
import { DynamicSidebarConfig } from './dynamic-sidebar-config.model';

@Component({
  selector: 'dynamic-sidebar-ref',
  templateUrl: './dynamic-sidebar-ref.component.html',
  styleUrls: ['./dynamic-sidebar-ref.component.scss']
})
export class DynamicSidebarRef<T extends DynamicSidebarContent> {

  visible: boolean;
  className: string;
  dismissible: boolean;
  showCloseIcon: boolean = true;
  primengAnimationDelay = 300;
  private contentRef: any;
  private exposedData: any;
  public content: T;
  public onOpen: Subject<any> = new Subject<any>();
  public onClose: Subject<any> = new Subject<any>();

  @ViewChild(DynamicContentDirective, { static: true }) sidebarContent: DynamicContentDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  init(content: DynamicSidebarContent, config: DynamicSidebarConfig = new DynamicSidebarConfig()) {
    const contentFactory = this.componentFactoryResolver.resolveComponentFactory(content as any);
    this.sidebarContent.viewContainerRef.clear();
    this.contentRef = this.sidebarContent.viewContainerRef.createComponent(contentFactory);
    this.content = this.contentRef.instance as T;
    this.content.data = config.data;
    this.className = config.className ? config.className : 'vida-sidebar';
    this.dismissible = !config.dismissible && config.dismissible !== false ? true : config.dismissible;
    this.showCloseIcon = config.showCloseIcon === undefined ? true : config.showCloseIcon;
    this.open();
  }

  open() {
    this.visible = true;
    this.onOpen.next(null);
  }


  close(data = null) {
    this.visible = false;
    this.exposedData = data;
    timer(this.primengAnimationDelay).subscribe(_ => this.onClose.next(this.exposedData));
    this.sidebarContent.viewContainerRef.clear();
  }
}
